import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
export default function SuccessEntry() {
  const identityStatus = localStorage.getItem("step3");
  const dominantOrientationStatus = localStorage.getItem("step4");
  const PrivacyAndHealthStatus = localStorage.getItem("step6");

  const statuses = [
    {
      name: identityStatus ? "Identity Completed" : "Identity Incomplete",
      status: identityStatus ? true : false,
    },
    {
      name: dominantOrientationStatus
        ? "Dominant Orientation Completed"
        : "Dominant Orientation Incomplete",
      status: dominantOrientationStatus ? true : false,
    },
    {
      name: PrivacyAndHealthStatus
        ? "Privacy Commitment Completed"
        : "Privacy Commitment Incomplete",
      status: PrivacyAndHealthStatus ? true : false,
    },
    {
      name: PrivacyAndHealthStatus ? "Sexual Health Completed" : "Sexual Health Incomplete",
      status: PrivacyAndHealthStatus ? true : false,
    },
  ];

  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center"></div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-pink-600  tracking-wide">Status: In Review</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Verification Process Complete!
            </h1>
            <p className="mt-2 text-base text-gray-500">
              We will contact you after reviewing your submission.
            </p>
            <div className="flex items-center justify-center flex-col mt-5">
              {statuses.map(status => {
                return (
                  <div
                    className={classNames(
                      status.status === true
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "inline-flex items-baseline px-2.5 mx-2 my-2 py-0.5 rounded-full text-sm font-medium "
                    )}
                  >
                    {status.status === true ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span>{status.name}</span>
                  </div>
                );
              })}
            </div>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-pink-600 hover:text-pink-500">
                Go back to the beginning <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href={`mailto:support@${process.env.REACT_APP_DOMAIN}`}
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Contact Support
          </a>
        </nav>
      </footer>
    </div>
  );
}
