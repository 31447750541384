import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  BanIcon,
  CameraIcon,
  DocumentSearchIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Not Disclosing Details about Fetish Circle",
    description:
      "Our community and platform is successful because it is private and exclusive. The BDSM community is full of timewasters and people with bad intentions. So in order to prevent them from finding their way into our platform, we request that you do not share any details about Fetish Circle to a third party.",
    icon: BanIcon,
    secondDescription:
      "This means not sharing our website link, our email details, what we do and anything else related to Fetish Circle. After after achieving level 3 membership, we will give you the ability to refer others but until then please resist the temptation of sharing information about Fetish Circle.",
  },
  {
    name: "Not Disclosing any information about any member of Fetish Circle",
    description:
      "Over the course of your time as a member of Fetish Circle, you may come across personally identifying information about either your submissive or fellow dommes. Many of our members wish to keep this part of their lives discrete, so we request that you do not share any information to any third party.",
    icon: EyeOffIcon,
    secondDescription:
      "This information can include: Sharing the fact that someone is a member of Fetish Circle, sharing the fact that someone is a submissive or dominant, sharing information about someone's residence or any other information. We hope that you will behave in a way that you would expect of others who are aware of your tendencies.",
  },
  {
    name: "Not prying personal information from your Submissives",
    description:
      "Many submissives hope to keep their submissive lives discrete and private. Over the course of your domination of a submissive, you may develop significant control over a submissive and we request that you do not abuse this control to exploit the submissive. ",
    icon: DocumentSearchIcon,
    secondDescription:
      "We have processes and tools in place to prevent this as well as an internal complaints line but we request that you do not attempt to pry further personal information from a submissive or attempt to engage in unwanted behaviour: e.g.  extreme domination, blackmail. We have an internal guideline on things to avoid and we hope you will adhere to that.",
  },
  {
    name: "No Recording of any Kind",
    description:
      "As part of our commitment to privacy, we request that all our members do not record any of the activities they engage in. No Videos and No Photos. We understand that some dommes wish to record their domination but we are strictly against this and cannot support you with this ambition.",
    icon: CameraIcon,
    secondDescription:
      "To maintain the privacy of our members, we also request that no photos or videos are sent between dommes and submissives. This would prevent accidental exposure of any of our members.",
  },
];

export default function Step5() {
  return (
    <div className="flex justify-center bg-gray-50 items-center w-full h-auto">
      <div className="relative  py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="lg:text-center">
            <h2 className="text-base text-pink-600 font-semibold tracking-wide uppercase">
              Confirming Sexual Health & Commitment to Privacy
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Sign our Non Disclosure Agreement
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Congratulations on reaching this far! All that is needed from you
              is a commitment to honour the privacy of our platform and its
              members and a declaration of sexual health. To help you understand
              what we are requesting you sign, below are the key points that we
              are asking you to agree to:
            </p>
          </div>

          <div className="mt-20 text-left">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-pink-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-semibold leading-6  text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.secondDescription}
                  </dd>
                </div>
              ))}
            </dl>
            <div className="mt-20 flex justify-between  rounded-md ">
              <Link
                to="/verification/step4"
                className="flex items-center shadow justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
              >
                Go Back
              </Link>
              <Link
                to="/verification/step6"
                className="flex ml-3 items-center shadow justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
