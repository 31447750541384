import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateProfile,
  updatePassword,
  setPersistence,
  browserSessionPersistence,
  User,
} from "firebase/auth";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { firebaseCredentials } from "./credentials";

const app = initializeApp(firebaseCredentials);
const auth = getAuth(app);

const logInWithEmailAndPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    await setPersistence(auth, browserSessionPersistence);
  } catch (err) {
    toast.error(err.code);
  }
};

const changePassword = async ({
  user,
  currentPassword,
  newPassword,
}: {
  user: User;
  currentPassword: string;
  newPassword: string;
}) => {
  toast.loading("Changing Password....");
  try {
    if (user && user.email) {
      await logInWithEmailAndPassword({ email: user.email, password: currentPassword });
      await updatePassword(user, newPassword);
      toast.success("Password Changed");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const sendVerificationEmail = async ({ user }: { user: User }) => {
  toast.loading("Sending Email....");
  try {
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user);
      toast.success("Verification Email Sent");
    }
  } catch (err) {
    toast.error(err.code);
  }
};

const registerWithEmailAndPassword = async ({
  email,
  password,
  name,
}: {
  email: string;
  password: string;
  name: string;
}) => {
  try {
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(user, { displayName: name });
    if (user && user.emailVerified === false) {
      // await sendEmailVerification(user); //temporary
      toast.success("Account Created: Please verify email");
    }
  } catch (err) {
    toast.error(err.code);
  }
};
const sendPasswordReset = async ({ email }: { email: string }) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("Password Reset Email Sent");
  } catch (err) {
    toast.error(err.code);
  }
};

const logout = async () => {
  try {
    await signOut(auth);
    toast.success("Logged Out Successfully");
  } catch (err) {
    toast.error(err.code);
  }
};


const refreshToken = async ({ user }: { user: User }): Promise<void> => {
  await user.getIdToken(true);
};

export {
  auth,
  logInWithEmailAndPassword,
  sendVerificationEmail,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  changePassword,

  refreshToken,
};
