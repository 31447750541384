import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import { toast } from "react-hot-toast";

import { Link } from "react-router-dom";
import Steps, { StepsProps } from "./components/Steps/Steps";
import Loading from "../../../shared/internals/components/loading/Loading";
import ErrorMessage from "../../../shared/internals/components/errorMessage/ErrorMessage";
import { useInsert_Form_ResponseMutation } from "../../../shared/infrastructure/graphql/generated/types";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../shared/infrastructure/firebase";

interface Values {
  nearest_location_hub: string;

  age_bracket: string;
  race: string;
  dominance_level: string;
  dominance_experience: string;
  domination_style: string;
  relationship_status: string;
  employment_status: string; //working
  fetishes: string; //put examples in text
  relationship_type_preference: string; // husband
  control_level: string; //
  relationship_location_preference: string; // virtual only, physical
  discretion_importance: string; //im public, im private
  financial_support: string;
  accomodation_type: string;
  training_access: string;
}

interface FieldsByStep {
  id: number;
  fields: any[];
}

enum StepStatus {
  current = "current",
  upcoming = "upcoming",
  complete = "complete",
}

const fieldsByStep: FieldsByStep[] = [
  {
    id: 0,
    fields: [
      "age_bracket",
      "nearest_location_hub",
      "relationship_status",
      "employment_status",
      "race",
      "dominance_experience",
      "dominance_level",
      "domination_style",
    ],
  },
  {
    id: 1,
    fields: [
      "relationship_type_preference",
      "control_level",
      "relationship_location_preference",
      "discretion_importance",
      "fetishes",
    ],
  },
  {
    id: 2,
    fields: ["financial_support", "accomodation_type", "training_access"],
  },
];

const fieldNames = {
  age_bracket: "age_bracket",
  domination_style: "domination_style",
  race: "race",
  dominance_level: "dominance_level",
  dominance_experience: "dominance_experience",
  relationship_status: "relationship_status",
  employment_status: "employment_status",
  fetishes: "fetishes",
  relationship_type_preference: "relationship_type_preference",
  control_level: "control_level",
  relationship_location_preference: "relationship_location_preference",
  discretion_importance: "discretion_importance",
  financial_support: "financial_support",
  accomodation_type: "accomodation_type",
  training_access: "training_access",
  nearest_location_hub: "nearest_location_hub",
};

const validationSchema = yup.object().shape({
  fetishes: yup.string().required("Fetishes are required"),
  domination_style: yup
    .string()
    .required("Please select an Response")
    .notOneOf(["-- select an option --"], "Please select an Response"),
  nearest_location_hub: yup
    .string()
    .required("Please select an Response")
    .notOneOf(["-- select an option --"], "Please select an Response"),
  race: yup
    .string()
    .required("Please select an Response")
    .notOneOf(["-- select an option --"], "Please select an Response"),
  dominance_level: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  dominance_experience: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  relationship_status: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  employment_status: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  relationship_type_preference: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  control_level: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  relationship_location_preference: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  discretion_importance: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  financial_support: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  accomodation_type: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  training_access: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
  age_bracket: yup
    .string()
    .required("Please select a Response")
    .notOneOf(["-- select an option --"], "Please select a Response"),
});

const locationOptions = [
  "San Francisco, USA",
  "New York City, USA",
  "Atlanta, USA",
  "London, UK",
  "Paris, France",
  "Brussels, Belgium",
  "Berlin, Germany",
  "Munich, Germany",
  "Hong Kong, China",
  "Tokyo, Japan",
  "Madrid, Spain",
];

const dominanceLevelOptions = [
  "I am only Submissive",
  "I am mostly Submissive, but I enjoy Dominating",
  "I am equally Submissive and Dominant",
  "I am mostly Dominant but I enjoy Submitting",
  "I am only Dominant",
  "I am not sure, but I love dominating and controlling men",
];

const dominanceExperienceOptions = [
  "I have no desire to dominate a man",
  "I have desire to dominate a man but I have never had the chance",
  "I have had 1 or 2 experiences dominating a man",
  "I have had between 2 and 10 experiences dominating a man",
  "I have had more than 10 experiences dominating a man",
  "I consider myself an experienced domme",
  "I regularly dominate men",
];

const dominanceStyleOptions = [
  "I am/want to be a Mommy Domme. I like to take care of my submissives and show them affection while still in control",
  "I am/want to be a Controlling Domme. I like my submissives to feel owned by me. ",
  "I am/want to be a Discipline Domme. I like punishing my submissives and giving them strict protocols to obey. ",
];

const relationshipLocationPreferenceOptions = [
  "Mostly Virtually - e.g. via Phone Calls",
  "Mostly Physically -e.g. In Person",
];

const relationshipTypePreferences = [
  "I am looking for a submissive to marry",
  "I am looking for a submissive for a long term monogamous relationship",
  "I am looking for a submissive that I control and can use to pleasure myself whenever I want and without much fuss",
  "I am looking for a submissive that I can dominate and pleasure myself with once in a while",
  "I am looking for a submissive to dominate just once or twice and never interact with again",
];

const controlLevelOptions = [
  "No Control - The Submissive regularly disobeys you including when aroused",
  "Little Control - The Submissive regularly disobeys but less frequently when aroused",
  "Some Control - The Submissive disobeys occasionally and you are able to carry out some punishments",
  "High Control - The Submissive rarely disobeys but you are not always able to have the submissive comply with your punishments and more demanding instructions.  ",
  "Complete Control - The Submissive rarely disobeys and finds himself compelled to obey your instructions. You feel completely comfortable and in control around your submissive and can punish and reward as you see fit.",
];

const discretionImportanceOptions = [
  "I don't tell anyone about my domination activities",
  "I tell only my partner about my domination activities",
  "I tell my close friends about my domination activities",
  "I am public about my domination activities",
];

const financialSupportOptions = [
  "I need no financial support",
  "I need a one time financial support to facilitate my domination activities",
  "I need ongoing financial support to facilitate my domination activities",
];

const accomodationTypeOptions = [
  "I live independently",
  "I share an accomodation with room mates",
  "I live with family or friends",
  "I do not have a stable accomodation",
];

const trainingAccessOptions = [
  "I want to have access as soon as possible",
  "I want access but sometime in the future",
  "I would like to learn more about what the training involves",
  "I am not interested in any training",
];

const Step4 = () => {
  const [user] = useAuthState(auth);
  const [steps, setSteps] = useState<StepsProps["steps"]>([
    {
      name: "Dominant Experience",
      subheading:
        "Please provide us a better understanding of your background as a Domme",
      status: "current",
    },
    {
      name: "Dominant Intentions",
      subheading:
        "Please let us know about your intentions and desires, so that we can provide you with the right support.",
      status: "upcoming",
    },
    {
      name: "Platform Support",
      subheading:
        "Please let us know what kinds of support from Fetish Circle you'd be interested in.",
      status: "upcoming",
    },
  ]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);
  const [successfulDetection, setSuccesfulDetection] = useState<boolean>(false);

  useEffect(() => {
    const item = localStorage.getItem("step4");
    if (item !== null) {
      toast.success(
        "You have already completed this step successfully, please click the continue button below"
      );
      setSuccesfulDetection(true);
    }
  }, []);

  const {
    register,
    trigger,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(validationSchema),
  });

  const [addFormResponse] = useInsert_Form_ResponseMutation({
    onError: (error) => {
      setMutationLoading(false);
      toast.error("Something went wrong, please try again");
    },
    onCompleted: () => {
      localStorage.setItem("step4", "success");

      toast.success(
        "Sucessfully Submitted, please click the continue button below"
      );
      setMutationLoading(false);
      setSuccesfulDetection(true);
      reset();
    },
  });

  const onSubmit = async (data: Values) => {
    setMutationLoading(true);
    await addFormResponse({
      variables: { email: user?.email ?? "no email", ...data },
    });
  };

  const isLoading = mutationLoading;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex-wrap flex flex-col bg-gray-50 items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-pink-600 uppercase">
          Confirming Dominant Orientation
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Questions about your Dominant Goals
        </p>
        <p className="mt-5  max-w-prose mx-auto text-xl text-gray-500">
          The following set of questions are less about verification and more
          about us knowing how to help you best achieve your goals as a Domme.
          Please be as honest as you can, there are no "wrong" answers here. We
          don't believe that you have to prove that you are a dominant to us,
          the fact that you have reached this stage proves you must have some
          dominant tendencies.
        </p>
      </div>

      <div className="mt-10">
        <Steps steps={steps} />
      </div>
      <div className="max-w-md w-full space-y-8">
        <div className="mt-8 sm:mx-auto sm:w-full  sm:max-w-md">
          <div className="bg-white mt-10 py-8 px-4 shadow sm:rounded-xl sm:px-10">
            <div className="text-gray-900 text-2xl  mb-2 font-bold">
              {steps[activeStep].name}
            </div>
            <p className="text-gray-400 text-sm mb-4">
              {steps[activeStep].subheading}
            </p>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              {activeStep === 0 && (
                <>
                  <div>
                    <label
                      htmlFor={fieldNames.race}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Race
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the race you identify as
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("race")}
                        id={fieldNames.race}
                        name={fieldNames.race}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>

                        <optgroup label="White">
                          <option value="White English">English</option>
                          <option value="White Welsh">Welsh</option>
                          <option value="White Scottish">Scottish</option>
                          <option value="White Northern Irish">
                            Northern Irish
                          </option>
                          <option value="White Irish">Irish</option>
                          <option value="White Gypsy or Irish Traveller">
                            Gypsy or Irish Traveller
                          </option>
                          <option value="White Other">
                            Any other White background
                          </option>
                        </optgroup>
                        <optgroup label="Mixed or Multiple ethnic groups">
                          <option value="Mixed White and Black Caribbean">
                            White and Black Caribbean
                          </option>
                          <option value="Mixed White and Black African">
                            White and Black African
                          </option>
                          <option value="Mixed White Other">
                            Any other Mixed or Multiple background
                          </option>
                        </optgroup>
                        <optgroup label="Asian">
                          <option value="Asian Indian">Indian</option>
                          <option value="Asian Pakistani">Pakistani</option>
                          <option value="Asian Bangladeshi">Bangladeshi</option>
                          <option value="Asian Chinese">Chinese</option>
                          <option value="Asian Other">
                            Any other Asian background
                          </option>
                        </optgroup>
                        <optgroup label="Black">
                          <option value="Black African">African</option>
                          <option value="Black African American">
                            African American
                          </option>
                          <option value="Black Caribbean">Caribbean</option>
                          <option value="Black Other">
                            Any other Black background
                          </option>
                        </optgroup>
                        <optgroup label="Other ethnic groups">
                          <option value="Arab">Arab</option>
                          <option value="Hispanic">Hispanic</option>
                          <option value="Latino">Latino</option>
                          <option value="Native American">
                            Native American
                          </option>
                          <option value="Pacific Islander">
                            Pacific Islander
                          </option>
                          <option value="Other">Any other ethnic group</option>
                        </optgroup>
                      </select>
                    </div>

                    {errors.race && (
                      <ErrorMessage>{errors.race?.message}</ErrorMessage>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor={fieldNames.age_bracket}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Age
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select your age bracket
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("age_bracket")}
                        id={fieldNames.age_bracket}
                        name={fieldNames.age_bracket}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        <option>0-18</option>
                        <option>19-25</option>
                        <option>26-34</option>
                        <option>35-44</option>
                        <option>45-54</option>
                        <option>55-64</option>
                        <option>65-74</option>
                        <option>75+</option>
                      </select>
                    </div>

                    {errors.age_bracket && (
                      <ErrorMessage>{errors.age_bracket?.message}</ErrorMessage>
                    )}
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.nearest_location_hub}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Location
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select our closest hub to you
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("nearest_location_hub")}
                        id={fieldNames.nearest_location_hub}
                        name={fieldNames.nearest_location_hub}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {locationOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.nearest_location_hub && (
                        <ErrorMessage>
                          {errors.nearest_location_hub?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.relationship_status}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Relationship status
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please let us know your current relationship status
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("relationship_status")}
                        id={fieldNames.relationship_status}
                        name={fieldNames.relationship_status}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        <option value="Single">Single</option>
                        <option value="Dating-Not Serious">
                          Dating - Not Serious
                        </option>
                        <option value="Dating-Not Serious">
                          Dating - Serious
                        </option>
                        <option value="Married">Married</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Separated">Separated</option>
                        <option value="Divorced">Divorced</option>
                      </select>
                      {errors.relationship_status && (
                        <ErrorMessage>
                          {errors.relationship_status?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.employment_status}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Employment status
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please let us know your current employment status
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("employment_status")}
                        id={fieldNames.employment_status}
                        name={fieldNames.employment_status}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        <option value="Employed">Employed</option>
                        <option value="Self-employed">Self-employed</option>
                        <option value="Out of work">Out of work</option>
                        <option value="Homemaker">Homemaker</option>
                        <option value="Student">Student</option>
                        <option value="Retired">Retired</option>
                        <option value="Unable to work">Unable to work</option>
                      </select>
                      {errors.employment_status && (
                        <ErrorMessage>
                          {errors.employment_status?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.dominance_level}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Level of Dominance
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the option below that best describes you
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("dominance_level")}
                        id={fieldNames.dominance_level}
                        name={fieldNames.dominance_level}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {dominanceLevelOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.dominance_level && (
                        <ErrorMessage>
                          {errors.dominance_level?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.dominance_experience}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Dominance Experience
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the option below that best describes you
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("dominance_experience")}
                        id={fieldNames.dominance_experience}
                        name={fieldNames.dominance_experience}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {dominanceExperienceOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.dominance_experience && (
                        <ErrorMessage>
                          {errors.dominance_experience?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.domination_style}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Dominance Style
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the domination style that attracts you the
                      most. We know that there is an overlap between the options
                      and that every domme is unique, but selecting an option
                      gives us some indication of what matters to you most.
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("domination_style")}
                        id={fieldNames.domination_style}
                        name={fieldNames.domination_style}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {dominanceStyleOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.domination_style && (
                        <ErrorMessage>
                          {errors.domination_style?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                </>
              )}

              {activeStep === 1 && (
                <>
                  <div className="">
                    <label
                      htmlFor={fieldNames.relationship_location_preference}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Domination Interactions
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select whether you are intending to dominate your
                      submissive in person or virtually
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("relationship_location_preference")}
                        id={fieldNames.relationship_location_preference}
                        name={fieldNames.relationship_location_preference}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {relationshipLocationPreferenceOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.relationship_location_preference && (
                        <ErrorMessage>
                          {errors.relationship_location_preference?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.relationship_type_preference}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Relationship with Submissive
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the type of relationship you want with your
                      submissive
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("relationship_type_preference")}
                        id={fieldNames.relationship_type_preference}
                        name={fieldNames.relationship_type_preference}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {relationshipTypePreferences.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.relationship_type_preference && (
                        <ErrorMessage>
                          {errors.relationship_type_preference?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.control_level}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Level of Control
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please select the level of control you want to achieve
                      over your submissive
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("control_level")}
                        id={fieldNames.control_level}
                        name={fieldNames.control_level}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {controlLevelOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.control_level && (
                        <ErrorMessage>
                          {errors.control_level?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.discretion_importance}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Personal Discretion
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please let us know how discrete you are about your
                      domination tendencies right now. If you join our platform,
                      Privacy will always be a priority for us and we intend to
                      share no information at all and expect you to guard the
                      privacy of our platform and members, but understanding
                      your discretion levels helps us give you better advice,
                      training and support.
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("discretion_importance")}
                        id={fieldNames.discretion_importance}
                        name={fieldNames.discretion_importance}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {discretionImportanceOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.discretion_importance && (
                        <ErrorMessage>
                          {errors.discretion_importance?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.fetishes}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Fetishes
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please list some of your key domination fetishes. Some of
                      the most common fetishes reported are :{" "}
                      <i>
                        pussy worship, ass worship, foot worship, breast
                        worship, body worship, pegging, giving humiliation,
                        golden showers, sissyfication, whipping, paddling and
                        denying pleasure
                      </i>
                    </span>
                    <div className="mt-1">
                      <input
                        {...register("fetishes")}
                        id={fieldNames.fetishes}
                        name={fieldNames.fetishes}
                        type="text"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      />
                      {errors.fetishes && (
                        <ErrorMessage>{errors.fetishes?.message}</ErrorMessage>
                      )}
                    </div>
                  </div>
                </>
              )}

              {activeStep === 2 && (
                <>
                  <div className="">
                    <label
                      htmlFor={fieldNames.financial_support}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Financial Support Requirement
                    </label>
                    <span className="text-gray-400 text-sm">
                      We believe that all dommes should have the ability and
                      access to a submissive that is committed to them. So we
                      provide financial support to help facilitate this. Please
                      let us know if you require financial support and we will
                      follow up in the future.
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("financial_support")}
                        id={fieldNames.financial_support}
                        name={fieldNames.financial_support}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {financialSupportOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.financial_support && (
                        <ErrorMessage>
                          {errors.financial_support?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.accomodation_type}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Accomodation Type
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please let us know what kind of accomodation you occupy.
                      This helps us provide you with guidance on transitioning
                      to at residence domination if that is an option you want
                      to consider.
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("accomodation_type")}
                        id={fieldNames.accomodation_type}
                        name={fieldNames.accomodation_type}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {accomodationTypeOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.accomodation_type && (
                        <ErrorMessage>
                          {errors.accomodation_type?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={fieldNames.training_access}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Training Access
                    </label>
                    <span className="text-gray-400 text-sm">
                      Please let us know if you would like to gain access to the
                      free tier of our training. We have compiled 100's of
                      techniques on how to break a man down so that a click from
                      your finger is enough to have him grovel at your behind.
                      The techniques are both verbal and physical and can have
                      varying levels of impact.
                    </span>
                    <div className="mt-1">
                      <select
                        {...register("training_access")}
                        id={fieldNames.training_access}
                        name={fieldNames.training_access}
                        className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                      >
                        <option disabled selected>
                          -- select an option --
                        </option>
                        {trainingAccessOptions.map((option) => {
                          return <option key={option}>{option}</option>;
                        })}
                      </select>
                      {errors.training_access && (
                        <ErrorMessage>
                          {errors.training_access?.message}
                        </ErrorMessage>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="flex  justify-between relative flex-row">
                {activeStep !== 0 && (
                  <button
                    type="button"
                    onClick={async () => {
                      const stepsCopy = [...steps];
                      stepsCopy[activeStep].status = StepStatus.upcoming;
                      stepsCopy[activeStep - 1].status = StepStatus.current;
                      setSteps(stepsCopy);
                      setActiveStep((currentStep) => currentStep - 1);
                    }}
                    className=" flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Back
                  </button>
                )}

                {activeStep !== steps.length - 1 && (
                  <button
                    type="button"
                    onClick={async () => {
                      await trigger(fieldsByStep[activeStep].fields);
                      if (Object.keys(errors).length === 0) {
                        const stepsCopy = [...steps];
                        stepsCopy[activeStep].status = StepStatus.complete;
                        stepsCopy[activeStep + 1].status = StepStatus.current;
                        setSteps(stepsCopy);
                        setActiveStep((currentStep) => currentStep + 1);
                      }
                    }}
                    style={{ marginLeft: "auto" }}
                    className=" flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Next
                  </button>
                )}
              </div>

              {activeStep === steps.length - 1 && (
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      className="h-5 w-5 text-pink-500 group-hover:text-pink-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Submit Form
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-md  text-center sm:max-w-3xl   lg:max-w-7xl">
        <div className="rounded-md  mt-10 flex justify-between  w-full ">
          <Link
            to="/verification/step3"
            className=" flex shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
          >
            Go Back
          </Link>

          <Link
            className=" flex ml-3 shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
            to={!successfulDetection ? "#" : "/verification/step5"}
          >
            {successfulDetection ? "Continue" : "Awaiting Form Completion"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Step4;
