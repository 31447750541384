import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import axios from "axios";

import Loading from "../../../shared/internals/components/loading/Loading";
import { classNames } from "../../../shared/internals/utils";
import { useInsert_Ip_And_HashMutation } from "../../../shared/infrastructure/graphql/generated/types";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../shared/infrastructure/firebase";
const faceapi = require("face-api.js");

interface Detection {
  name: string;
  note: string;
  stat: number | string;
}

const Step3 = () => {
  const [user] = useAuthState(auth);

  const videoHeight = 300;
  const videoWidth = 440;
  const [initialising, setInitiliasing] = useState(false);
  const [detecting, setDetecting] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isVideoAvailable, setIsVideoAvailable] = useState<boolean>(false);
  const [detections, setDetections] = useState<Detection[]>([]);
  const [detectionAttempt, setDetectionAttempt] = useState<number>(1);
  const [successfulDetection, setSuccesfulDetection] = useState<boolean>(false);
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);
  const [IPAddress, setIPAddress] = useState<string>("None Found");

  const obtainIPAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIPAddress(res.data.IPv4 ?? "None Found");
  };

  useEffect(() => {
    obtainIPAddress();
    const item = localStorage.getItem("step3");
    if (item !== null) {
      toast.success(
        "You have already completed this step successfully, please click continue"
      );
      setSuccesfulDetection(true);
    }
  }, []);

  const isLoading = mutationLoading;

  const [addIPandHash] = useInsert_Ip_And_HashMutation({
    onError: (error) => {
      setMutationLoading(false);
      startVideo();
    },
    onCompleted: () => {
      setMutationLoading(false);
      startVideo();
    },
  });

  useEffect(() => {
    const loadModels = async () => {
      setInitiliasing(true);
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        faceapi.nets.ageGenderNet.loadFromUri("/models"),
      ]).then(startVideo);
    };
    loadModels();
  }, []);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        if (videoRef) {
          setIsVideoAvailable(true);
          setInitiliasing(false);
          videoRef.current!.srcObject = stream;
        }
      })
      .catch((e) => {
        toast.error(
          "Permission denied, please refresh the page or grant the tab permission to use your camera."
        );
      });
  };

  const handleCapture = async () => {
    if (initialising) {
      setInitiliasing(false);
    }
    setDetecting(true);
    setDetectionAttempt((attempts) => attempts + 1);

    const displaySize = {
      width: videoWidth,
      height: videoHeight,
    };
    faceapi.matchDimensions(canvasRef.current!, displaySize);

    const detections = await faceapi
      .detectAllFaces(videoRef.current!, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceExpressions()
      .withAgeAndGender();

    if (detections.length === 0) {
      setDetections([
        {
          name: "Faces Detected",
          stat: "None",
          note: "Please try again",
        },
      ]);
    }
    if (detections.length > 1) {
      setDetections([
        {
          name: "Faces Detected",
          stat: "Multiple",
          note: `${detections.length} detected, try again`,
        },
      ]);
    }

    if (detections.length === 1) {
      setDetections([
        {
          name: "Faces Detected",
          stat: "Single",
          note: "Successful",
        },
        {
          name: "Gender Detected",
          stat: detections[0].gender,
          note: `with ${detections[0].genderProbability.toLocaleString("en", {
            style: "percent",
          })} probability`,
        },
      ]);

      if (detections[0].gender === "female") {
        setSuccesfulDetection(true);
        const canvas = document.createElement("canvas");
        canvas.width = 640;
        canvas.height = 480;
        const ctx = canvas.getContext("2d");
        ctx!.drawImage(videoRef.current!, 0, 0, canvas.width, canvas.height);
        const dataURI = canvas.toDataURL("image/png");
        console.log(dataURI);
        setMutationLoading(true);
        await addIPandHash({
          variables: {
            ip_address: IPAddress,
            hash: dataURI ?? "None Found",
            email: user?.email ?? "No email obtained",
          },
        });
        setMutationLoading(false);
        localStorage.setItem("step3", "success");
        toast.success(
          "Successful Detection, Please click the continue button below"
        );
      }
    }

    setDetecting(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex justify-center bg-gray-50  items-center w-full h-auto">
      <div className="relative  py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-pink-600 uppercase">
            Confirming Identity
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Webcam Based Confirmation
          </p>
          <p className="mt-5  max-w-prose mx-auto text-xl text-gray-500">
            We'd like to confirm that you are a female. Please enable your
            webcam or your front facing camera and look directly at it. Then
            click the detect gender button below. Our smart AI will detect your
            gender. If it detects you as a female, you will be able to click the{" "}
            <b>continue</b> button below. It may take around 30 attempts. Please
            ensure good lighting is available.
          </p>
          {isVideoAvailable === false ? (
            <div className="w-full h-auto mt-8 mb-10 flex flex-col items-center">
              <div className="bg-white h-48 border-pink-500 border-2 w-full flex items-center justify-center py-8 px-4 shadow sm:rounded-xl sm:px-10">
                <div className="italic underline">
                  In a moment, you will receive a request to allow your browser
                  to turn on your webcam/camera, please accept this request. You
                  may need to refresh this page if you have granted permission
                  and you cannot see yourself on this page.
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex-col sm:flex-row flex items-center justify-center">
              <div className="flex flex-col items-center w-70 md:w-96  mt-8 mb-10 justify-center">
                <video
                  ref={videoRef}
                  autoPlay
                  muted
                  height={videoHeight}
                  width={videoWidth}
                />
                <canvas className="hidden" ref={canvasRef} />
                <button
                  disabled={initialising || detecting}
                  onClick={async () => await handleCapture()}
                  className="w-full mt-1 lex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200 md:py-4 md:text-lg md:px-10"
                >
                  {initialising
                    ? "Initialising"
                    : detecting
                    ? "Detecting"
                    : `Click to Detect Gender - Attempt ${detectionAttempt}`}
                </button>
              </div>
              <div className="  h-full sm:ml-10 flex flex-col ">
                <div>
                  <div
                    className={classNames(
                      initialising === false
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                    )}
                  >
                    {initialising === false ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span>
                      {initialising === true
                        ? "Initialising"
                        : "Ready to Detect"}
                    </span>
                  </div>
                </div>
                <div className="mt-2">
                  {detections.map((item) => {
                    return (
                      <div
                        key={item.name}
                        className="px-4 py-5 mt-2 bg-white  border-2 border-pink-500 shadow rounded sm:p-6"
                      >
                        <dt className="text-base font-normal text-gray-900">
                          {item.name}
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-pink-600">
                            {item.stat}
                            <span className="ml-2 text-sm font-medium text-gray-500">
                              {item.note}
                            </span>
                          </div>
                        </dd>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="rounded-md mt-10 flex justify-between ">
            <Link
              to="/verification/step2"
              className=" flex shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
            >
              Go Back
            </Link>

            <Link
              className=" flex ml-3 shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
              to={!successfulDetection ? "#" : "/verification/step4"}
            >
              {!successfulDetection
                ? "Awaiting Succesful Detection"
                : "Continue"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
