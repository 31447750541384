import { ExclamationIcon } from "@heroicons/react/outline";

const Error = () => {
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <div className="rounded-md bg-pink-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-pink-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-pink-800">Attention needed</h3>
            <div className="mt-2 text-sm text-pink-700">
              <p>
                Sorry, something went wrong our end. Please contact your overseer elisa@
                {process.env.REACT_APP_DOMAIN} to resolve this issue
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
