import { ReactElement, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../infrastructure/firebase/index";

interface PrivateRouteProps extends RouteProps {
  component: (props: any) => ReactElement;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return null;
  }

  return (
    <Route {...rest} render={props => (user ? <Component {...props} /> : <Redirect to="/" />)} />
  );
};

export default PrivateRoute;
