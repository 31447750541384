import React from "react";
import "./Warning.css";
import "./Warning.scss";
import { Redirect } from "react-router-dom";

class Warning extends React.Component {
  state = {
    preloaderClose: false,
    enter: false,
  };

  componentDidMount() {
    setTimeout(() => this.setState({ preloaderClose: true }), 1500);
  }

  render() {
    let preloaderClass = "sch_hola";
    if (this.state.preloaderClose === true) {
      preloaderClass = "sch_hola closed";
    }
    let redirect = null;
    if (this.state.enter) {
      redirect = <Redirect push to="/sign-in" />;
    }

    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#252328" }}>
          <div className={preloaderClass}>
            <div className="sch_preloader">
              <span></span>
              <span></span>
            </div>
          </div>

          <div className="splash">
            <div className="splash_svg">
              <svg width="100%" height="100%">
                <rect width="100%" height="100%" />
              </svg>
            </div>

            <div className="splash_minimize">
              <svg width="100%" height="100%">
                <rect width="100%" height="100%" />
              </svg>
            </div>
          </div>

          <div className="warning_text">
            <p>Committed</p>
            <p>
              to...<>Her </>{" "}
            </p>

            <button onClick={() => this.setState({ enter: true })}>Enter</button>

            <div className="forewarning">
              This web site contains material not suitable for those under the age of 18. By
              clicking enter you agree that you are over 18. If you are under the age of 18, or if
              you are accessing from a location that prohibits the viewing of nudity or adult
              oriented material such as those listed here, or if you find images, videos, or verbal
              descriptions of men and women in explicit adult context offensive, please EXIT NOW!
            </div>
          </div>
          {redirect}
        </div>
      </React.Fragment>
    );
  }
}

export default Warning;
