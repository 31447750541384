import { Link } from "react-router-dom";

export default function Step2() {
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-gray-50  overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            <h2 className="leading-6 text-pink-600 font-semibold tracking-wide uppercase">
              Privacy & Security
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              What is the Verification Process?
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="prose prose-pink prose-lg text-gray-500 lg:max-w-none">
                <p>
                  From our records we can see that you have been referred by{" "}
                  <span className="bg-pink-200 p-1 rounded text-black font-semibold">
                    Dominic
                  </span>{" "}
                  whose role is a{" "}
                  <span className="bg-pink-200 p-1 rounded text-black font-semibold">
                    Submissive
                  </span>
                </p>
                <p>
                  We now need to vet that you are a lady and that you are
                  committed to our principles. This is important as Fetish
                  Circle is a private platform and we hope our members will
                  respect our principles so that all our members can benefit
                  equally and without fear of a loss of privacy or reputation.
                </p>

                <h2>What does this mean for you?</h2>
                <p>
                  The first thing we would like to say is Welcome! We are all
                  about supporting fellow dominant ladies and we are keen to
                  have a dominant Lady benefit from our platform.
                </p>
                <p>
                  We allow both dominant woman and submissive men on our
                  platform but the access we grant submissives is extremely low.
                  They are not aware of most of the services we provide
                  dominants. For example, they are not aware of the training on
                  how to break a submissive down that we provide dominants. We
                  expect you as a fellow domme to not share this information
                  with any submissive you come in contact or dominants outside
                  our platform.
                </p>
                <p>
                  Before a submissive is allowed to join our platform, we put a
                  submissive through an extensive vetting process. Some of our
                  dommes have public facing roles and so we try to do our best
                  to not allow submissives who are not genuine and not privacy
                  conscious to join our platform. Our vetting process for
                  submissives includes:
                </p>
                <ol>
                  <li>
                    <b>Confirming Identity:</b> We obtain personal information
                    such as full name, address as well as a form of ID.
                  </li>
                  <li>
                    <b>Confirming Sexual Health: </b> We request STI test
                    results for both Urine and Blood test.
                  </li>
                  <li>
                    <b> Confirming Submissive Orientation: </b> We present some
                    screening questions and request a 1 time payment as an
                    indication of commitment to our principles.
                  </li>
                  <li>
                    <b> Confirming Commitment to Privacy: </b> We request that
                    our submissives sign a contractual Non Disclose Agreement
                    via email.
                  </li>
                </ol>
                <p>
                  This is an intrusive vetting process but we do this to protect
                  you as well as your fellow dominant ladies from having a poor
                  experience with any of the submissives on our platform. We
                  also do this to ensure that we can prevent and act quickly if
                  submissives choose to breach the privacy of individuals using
                  our platform. In the past, we have unfortunately have had to
                  request legal injunctions in the US though this has been for
                  exceptional cases.
                </p>
                <h2>
                  Does this mean I will have the undergo the same Vetting
                  Process?
                </h2>

                <p>
                  Absolutely Not! As you are a dominant female, you are superior
                  to a submissive and so we will not treat you the same and
                  request that you complete an intrusive vetting process.{" "}
                </p>
                <p>
                  However, this does not mean that we have no vetting process
                  for dominants at all. Our vetting process still exists but it
                  is a lot less intrusive.
                </p>
                <ol>
                  <li>
                    <b>Confirming Identity: </b>We do not obtain any personal
                    information from you. We simply verify that you are a female
                    via your webcam. No Image is stored, our AI bot detects if
                    you are a male or female to prevent false referrals from
                    being successful.
                  </li>
                  <li>
                    <b> Confirming Sexual Health:</b> We do not request any
                    proofs from dommes but instead rely on self reporting. We
                    rely on you to inform us whether you are in good sexual
                    health.
                  </li>
                  <li>
                    <b> Confirming Dominant Orientation: </b>We present some
                    screening questions and we do not request any payment to
                    undergo the vetting process. These questions are less about
                    verifying your dominant tendencies but more for us to figure
                    out how we can help you achieve your goals as a domme.
                  </li>
                  <li>
                    <b>Confirming Commitment to Privacy:</b> We send you a
                    contractual non disclose agreement via email and request
                    that you print it, sign it and email it to us.
                  </li>
                </ol>
                <p>
                  We hope that you are comfortable with this vetting process and
                  find it a reasonable balance between protecting our users
                  (which may include you in the future!) and not prying into the
                  life of our fellow dommes.
                </p>
              </div>
              <div className="mt-6 prose prose-pink prose-lg text-gray-500 lg:mt-0">
                <h2>What is our Expectation of you?</h2>
                <p>
                  Our only expectation is that you maintain the privacy of our
                  platform and our users. That means not sharing details about
                  our platform with others, not granting access to our platform
                  to other individuals and not sharing details or information
                  about anyone you meet via the platform. We also expect you not
                  to share details with a submissive on our platform that they
                  should not be aware of.
                </p>
                <p>
                  Apart from that, we hope that this platform helps you acquire
                  the submissive you desire. We hope our training helps you
                  control and train your submissive and lets you live the life
                  of pleasure and service that every Lady deserves.
                </p>
                <h2>What are the benefits of getting verified?</h2>
                <ol>
                  <li>
                    <b>Information about the submissive that referred you:</b>{" "}
                    We believe that dommes should always have as much
                    information as possible about their submissive. Not personal
                    private information but information relating to his
                    submissive level, past experiences and anything that may be
                    useful to the domme. On the other hand, a submissive should
                    know nothing about a verified Domme and should simply be
                    happy he is receiving her attention.
                  </li>
                  <li>
                    <b>Achieving Membership Level 2:</b> Your current state is
                    an unverified member, after completing the verification
                    process, you will be eligible for all services provided to
                    Membership level 2 dommes. Click{" "}
                    <Link to={"/verification/step1"}>here</Link> to see a full
                    list of services.
                  </li>
                  <li>
                    <b>Access to Training:</b> We have both a free tier and a
                    paid tier for access to our world class training on how to
                    break down men through physical and psychological techniques
                    to serve your every whim. As soon as you are verified, you
                    can access the free tier.
                  </li>
                  <li>
                    <b>Financial Support & Submissive Allocation:</b> We provide
                    financial support for dommes that need further finances to
                    have the relationship they want. This may be covering hotel
                    costs, toys cost and more. If you are seeking a submissive,
                    we will be able to begin helping you find a submissive that
                    meets your criteria.
                  </li>
                </ol>
                <h2>More Questions?</h2>
                <p>
                  If you are at all uncomfortable with anything you've read so
                  far, anything you've seen on our site or anything you've read
                  via email interaction, please stop and let your direct point
                  of contact know. For you, your direct point of contact is{" "}
                  <span className="bg-pink-200 p-1 rounded text-black font-semibold">
                    elisa.abelard@fetishcircle.eu.org
                  </span>
                  . We want you to be fully aware of everything before you join
                  our platform and for us to resolve any questions a fellow
                  domme may have. Your direct point of contact is a fellow domme
                  who has gone through the whole process and so she is in the
                  best position to help you.
                  <p>
                    Almost every month we hear of a domme who thinks that this
                    service we provide is a "bit too much" or "not for me". But
                    we want to let you know that Fetish Circle is for every
                    female that is dominant and privacy conscious. We have
                    thousands of dommes in our community across multiple
                    countries. We don't discriminate by age, race or experience.
                    As long as you are a dominant female that is committed to
                    privacy, we will help you the best that we can.
                  </p>
                  <p>
                    Please let us know what we can do differently as we are
                    always keen for feedback. Please send any comments you have
                    to{" "}
                    <span className="bg-pink-200 p-1 rounded text-black font-semibold">
                      feedback@fetishcircle.eu.org
                    </span>
                  </p>
                </p>
              </div>
            </div>
            <div className="mt-8 flex justify-between  rounded-md ">
              <Link
                to="/verification/step1"
                className="flex items-center shadow justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
              >
                Go Back
              </Link>
              <Link
                to="/verification/step3"
                className="flex ml-3 items-center shadow justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
              >
                Start Verification Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
