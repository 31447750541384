import {
  LockClosedIcon,
  CurrencyDollarIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  VideoCameraIcon,
  SupportIcon,
  KeyIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Access to Genuine Submissives",
    fee: "$800",
    target: "Professional Dommes",
    membership: "Level 2 Dommes",
    icon: FingerPrintIcon,
    text: " Our platform 'The Domme's Playground' allows pro-dommes to consistently find paying submissives in their area allowing pro-dommes to succeed on their terms.",
  },
  {
    name: "Community Platform",
    fee: "None",
    target: "All Dommes",
    membership: "Level 3 Dommes",
    icon: UserGroupIcon,
    text: "We have over 7,000 dommes on our main platform. A digital and in-person community that can provide support and relatability to other dominatrixes. ",
  },
  {
    name: "Breaking the Sub Training",
    fee: "$800",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: ShieldCheckIcon,
    text: "Years of research has helped us develop techniques to train a submissive and achieve full control and we have collated them into an online training platform so you can have the relationship you want.",
  },
  {
    name: "Feature Content Support ",
    fee: "$250 + Various",
    target: "Professional Dommes",
    membership: "Level 2 Dommes",
    icon: VideoCameraIcon,
    text: "Some of our dommes release content and we provide support through recording equipment, script creation, introductions to directors and producers, subsidized production costs and more. ",
  },
  {
    name: "Submissive Allocation Support",
    fee: "None",
    target: "LifeStyle Dommes",
    membership: "Level 2 Dommes",
    icon: SupportIcon,
    text: "For Domminatrixes currently without a submissive to serve them, we help find a discrete, appropriate submissive and provide ongoing financial and further support to faciliate domination on her terms.",
  },
  {
    name: "Support in Finding a Life Partner",
    fee: "$800",
    target: "LifeStyle Dommes",
    membership: "Level 2 Dommes",
    icon: KeyIcon,
    text: "Our 'LifeStyle Domme Platform' helps dominant ladies filter from a large pool of submissives to find a long term submissive partner of their choice. Thousands of couples have been matched in authentic female led relationships.",
  },
  {
    name: "Adhoc Financial Support",
    fee: "None",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: CurrencyDollarIcon,
    text: "We are strong believers in Female Supremacy. We believe every Lady should have access to a submissive that pleases them so we provide financial support to make this happen for dommes who do not have the means.",
  },
  {
    name: "Privacy & Security",
    fee: "None",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: LockClosedIcon,
    text: "We are absolutely committed to discretion and privacy. Our platforms are referral only and we make sure we vet all our members. We do not share any data and we make sure our members can continue dominating without worrying about public exposure.",
  },
];

export default function Step1() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-pink-600 uppercase">
          Supporting Dominatrixes
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          What is Fetish Circle
        </p>
        <p className="mt-5  max-w-prose mx-auto text-xl text-gray-500">
          At this point, you should already be aware of what Fetish Circle is
          all about. To put simply, we are an organisation founded by
          Dominatrixes committed to helping other Dominatrixes prosper while
          keeping their privacy and independence! Some of the things we do
          include:
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-pink-500 rounded-md shadow-lg">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="text-sm font-bold text-gray-400 italic">
                      Required Fee: {feature.fee}
                    </p>
                    <p className="text-sm font-bold text-gray-400 italic">
                      Target: {feature.target}
                    </p>
                    <p className="text-sm font-bold text-gray-400 italic">
                      Membership Level: {feature.membership}
                    </p>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="rounded-md mt-10 flex justify-between ">
            <Link
              to="/"
              className=" flex shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
            >
              Go Back
            </Link>
            <Link
              to="/verification/step2"
              className=" flex ml-3 shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
            >
              Continue
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
