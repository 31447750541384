import React, { ReactElement } from "react";
import DuplicateSignIn from "../../../modules/authentication/duplicateSignIn/page/DuplicateSignIn";
import Warning from "../../../modules/authentication/warning/Warning";
import NotFound from "../../../modules/notFound/notFound/page/NotFound";
import Step1 from "../../../modules/steps/Step1/Step1";
import Step2 from "../../../modules/steps/Step2/Step2";
import Step3 from "../../../modules/steps/Step3/Step3";
import Step4 from "../../../modules/steps/Step4/Step4";
import Step5 from "../../../modules/steps/Step5/Step5";
import Step6 from "../../../modules/steps/Step6/Step6";
import SuccessEntry from "../../../modules/steps/SuccessEntry/SuccessEntry";
import WelcomeNew from "../../../modules/welcome/welcome/page/WelcomeNew";



export interface Route {
  path: string;
  exact: boolean;
  main: () => ReactElement;
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/not-found",
    main: NotFound,
    exact: true,
  },
];

// routes that can be access when user is inauthenticated
// if paid - reroute to x
// if not paid - reroute to y
export const restrictedRoutes: Route[] = [
  {
    path: "/",
    main: Warning as unknown as Route['main'],
    exact: true,
  },
  {
    path: "/sign-in",
    main: DuplicateSignIn,
    exact: true,
  },
  
];

// routes that can be accessed if user is authenticated
export const privateRoutes: Route[] = [
  {
    path: "/welcome",
    main: WelcomeNew,
    exact: true,
  },
  {
    path: "/verification/step1",
    main: Step1,
    exact: true,
  },
  {
    path: "/verification/step2",
    main: Step2,
    exact: true,
  },
  {
    path: "/verification/step3",
    main: Step3,
    exact: true,
  },
  {
    path: "/verification/step4",
    main: Step4,
    exact: true,
  },
  {
    path: "/verification/step5",
    main: Step5,
    exact: true,
  },
  {
    path: "/verification/step6",
    main: Step6,
    exact: true,
  },
  {
    path: "/verification/success",
    main: SuccessEntry,
    exact: true,
  },

];

// routes that can be accessed if user has paid
export const contentRoutes: Route[] = [];
