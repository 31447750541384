import {
  contentRoutes,
  privateRoutes,
  publicRoutes,
  restrictedRoutes,
} from "./shared/internals/routes/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "./shared/internals/components/layout/Layout";
import PrivateRoute from "./shared/internals/components/privateRoute/PrivateRoute";
import RestrictedRoute from "./shared/internals/components/restrictedRoute/RestrictedRoute";
import LogRocket from "logrocket";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./shared/infrastructure/firebase";
import ApolloWrapper from "./shared/infrastructure/graphql/ApolloWrapper";

function App() {
  return (
    <Router>
      <ApolloWrapper>
        <CoreApp />
        <Toaster toastOptions={{ duration: 4000 }} position={"top-right"} />
      </ApolloWrapper>
    </Router>
  );
}

const CoreApp = () => {
  return (
    <>
      <Switch>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              exact={route.exact}
              key={index}
              path={route.path}
              component={route.main}
            />
          );
        })}

        {restrictedRoutes.map((route, index) => {
          return (
            <RestrictedRoute
              exact={route.exact}
              key={index}
              path={route.path}
              redirectPath={route.redirectPath}
              component={route.main}
            />
          );
        })}

        {privateRoutes.map((route, index) => {
          return (
            <PrivateRoute
              exact={route.exact}
              key={index}
              path={route.path}
              component={() => {
                return <route.main />;
              }}
            />
          );
        })}

        <Redirect to="/not-found" />
      </Switch>
    </>
  );
};

export default App;
