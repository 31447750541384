import { useEffect, useState } from "react";
import IconOnlyLogo from "../../../assets/logo/iconOnlyLogo.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import { toast } from "react-hot-toast";

import { Link } from "react-router-dom";
import Loading from "../../../shared/internals/components/loading/Loading";
import ErrorMessage from "../../../shared/internals/components/errorMessage/ErrorMessage";
import { useInsert_Form_Response_TwoMutation } from "../../../shared/infrastructure/graphql/generated/types";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../shared/infrastructure/firebase";

interface Values {
  is_sexually_healthy: string;
  sexual_health_note: string;
  last_clinic_visit: string;
  clinic_visit_note: string;
  uploaded_file: string;
}

const fieldNames = {
  is_sexually_healthy: "is_sexually_healthy",
  sexual_health_note: "sexual_health_note",
  last_clinic_visit: "last_clinic_visit",
  clinic_visit_note: "clinic_visit_note",
  uploaded_file: "uploaded_file",
};

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const validationSchema = yup.object().shape({
  is_sexually_healthy: yup
    .string()
    .required("Please select an Response")
    .notOneOf(["-- select an option --"], "Please select an Response"),
  last_clinic_visit: yup
    .string()
    .required("Please select an Response")
    .notOneOf(["-- select an option --"], "Please select an Response"),
  //figure out file validation yup
});

const isSexuallyHealthyOptions = ["Yes", "No", "I am not sure"];
const lastClinicVisit = [
  "Within the last month",
  "within the last 6 months",
  "within the last 12 months",
  "within the last 2 years",
  "within the last 5 years",
  "Longer than 5 years ago",
  "Never",
];

const Step6 = () => {
  const [user] = useAuthState(auth);

  const [mutationLoading, setMutationLoading] = useState<boolean>(false);
  const [successfulDetection, setSuccesfulDetection] = useState<boolean>(false);
  let history = useHistory();
  useEffect(() => {
    const item = localStorage.getItem("step6");
    if (item !== null) {
      toast.success(
        "You have already completed this step successfully, please click the continue button below"
      );
      setSuccesfulDetection(true);
    }
  }, []);

  const {
    register,
    trigger,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(validationSchema),
  });

  const [addFormResponseTwo] = useInsert_Form_Response_TwoMutation({
    onError: (error) => {
      setMutationLoading(false);
      toast.error("Something went wrong, please try again");
    },
    onCompleted: () => {
      localStorage.setItem("step6", "success");
      toast.success(
        "Sucessfully Submitted, please click the continue button below"
      );
      setMutationLoading(false);
      setSuccesfulDetection(true);
      reset();
      history.push("/verification/success");
    },
  });

  const onSubmit = async (data: Values) => {
    setMutationLoading(true);
    const File = data.uploaded_file[0] as unknown as File;
    const base64 = (await toBase64(File)) as string;
    await addFormResponseTwo({
      variables: {
        is_sexually_healthy: data.is_sexually_healthy,
        clinic_visit_note: data.clinic_visit_note,
        sexual_health_note: data.sexual_health_note,
        uploaded_file: base64 ?? "No File",
        extension: File.name ?? "No Name",
        last_clinic_visit: data.last_clinic_visit,
        email: user?.email ?? "no email",
      },
    });
  };

  const isLoading = mutationLoading;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex-wrap flex flex-col bg-gray-50 items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-pink-600 uppercase">
          Confirming Sexual Health & Commitment to Privacy{" "}
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Non Disclose Agreement & Sexual Health Declaration
        </p>
        <p className="mt-5  max-w-prose mx-auto text-xl text-gray-500">
          Below is a Non Disclosure Agreement. Please download the file, print
          it, sign it and upload the signed copy below. Then complete the Sexual
          Health Declaration and you will be verified and ready to begin
          dominating!{" "}
          <span className="bg-pink-200 p-1 rounded text-black font-semibold">
            COVID-19 UPDATE: Due to Covid-19 you can simply sign the document
            digitally.
          </span>
        </p>
      </div>

      <div className="max-w-md w-full space-y-8">
        <div className="mt-8 sm:mx-auto sm:w-full  sm:max-w-md">
          <div className="bg-white mt-10 py-8 px-4 shadow sm:rounded-xl sm:px-10">
            <div className="text-gray-900 text-2xl  mb-2 font-bold">
              Sexual Health Declaration
            </div>
            <p className="text-gray-400 text-sm mb-4">
              We are aware that sexual health is an extremely sensitive piece of
              information. This is why we do not share this information at all,
              even internally. This is purely so that we can protect our members
              sexual health.
            </p>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <label
                  htmlFor={fieldNames.is_sexually_healthy}
                  className="block text-sm font-medium text-gray-700"
                >
                  Current Sexual health
                </label>
                <span className="text-gray-400 text-sm">
                  Have you ever contracted any sexually transmitted infections?
                  All our Submissives are subjected to monthly STI check ups. We
                  do not do this with our dommes but rely on you to provide
                  accurate information.
                </span>
                <div className="mt-1">
                  <select
                    {...register("is_sexually_healthy")}
                    id={fieldNames.is_sexually_healthy}
                    name={fieldNames.is_sexually_healthy}
                    className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  >
                    <option disabled selected>
                      -- select an option --
                    </option>
                    {isSexuallyHealthyOptions.map((option) => {
                      return <option key={option}>{option}</option>;
                    })}
                  </select>
                  {errors.is_sexually_healthy && (
                    <ErrorMessage>
                      {errors.is_sexually_healthy?.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="">
                <label
                  htmlFor={fieldNames.sexual_health_note}
                  className="block text-sm font-medium text-gray-700"
                >
                  Optional Further Information
                </label>
                <span className="text-gray-400 text-sm">
                  If you selected "Yes" or "I am not sure" in the previous
                  question, please provide additional details
                </span>
                <div className="mt-1">
                  <input
                    {...register("sexual_health_note")}
                    type="text"
                    id={fieldNames.sexual_health_note}
                    name={fieldNames.sexual_health_note}
                    className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />

                  {errors.sexual_health_note && (
                    <ErrorMessage>
                      {errors.sexual_health_note?.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="">
                <label
                  htmlFor={fieldNames.last_clinic_visit}
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Clinic Visit
                </label>
                <span className="text-gray-400 text-sm">
                  Please can you share your last completed an STI check up.
                </span>
                <div className="mt-1">
                  <select
                    {...register("last_clinic_visit")}
                    id={fieldNames.last_clinic_visit}
                    name={fieldNames.last_clinic_visit}
                    className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  >
                    <option disabled selected>
                      -- select an option --
                    </option>
                    {lastClinicVisit.map((option) => {
                      return <option key={option}>{option}</option>;
                    })}
                  </select>

                  {errors.last_clinic_visit && (
                    <ErrorMessage>
                      {errors.last_clinic_visit?.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="">
                <label
                  htmlFor={fieldNames.clinic_visit_note}
                  className="block text-sm font-medium text-gray-700"
                >
                  Optional Further Information
                </label>
                <span className="text-gray-400 text-sm">
                  If known, please can you share the results of the latest test
                </span>
                <div className="mt-1">
                  <input
                    {...register("clinic_visit_note")}
                    type="text"
                    id={fieldNames.clinic_visit_note}
                    name={fieldNames.clinic_visit_note}
                    className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />

                  {errors.clinic_visit_note && (
                    <ErrorMessage>
                      {errors.clinic_visit_note?.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>

              <div className="text-gray-900 text-2xl  font-bold">
                Non Disclosure Agreement
              </div>
              <p className="text-gray-400 text-sm mb-4 ">
                This is the final step of your verification!
              </p>
              <div className="w-full flex items-center justify-center">
                <Link
                  to="/NDA.docx"
                  target={"_blank"}
                  download={true}
                  className="w-full mt-1 text-center lex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200 md:py-4 md:text-lg md:px-10"
                >
                  Download File
                </Link>
              </div>

              <div className="">
                <label
                  htmlFor={fieldNames.uploaded_file}
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload File
                </label>
                <span className="text-gray-400 text-sm">
                  Please upload a signed version of the NDA. The following file
                  formats are accepted = .odt, .pdf, .doc, .docx and .pages
                </span>
                <div className="mt-1">
                  <input
                    {...register("uploaded_file")}
                    type="file"
                    required
                    accept=".doc, .docx, .pages, .odt, .pdf"
                    id={fieldNames.uploaded_file}
                    name={fieldNames.uploaded_file}
                    className=" block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />

                  {errors.uploaded_file && (
                    <ErrorMessage>{errors.uploaded_file?.message}</ErrorMessage>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-pink-500 group-hover:text-pink-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Submit Form
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-md  text-center sm:max-w-3xl   lg:max-w-7xl">
        <div className="rounded-md  mt-10 flex justify-between  w-full ">
          <Link
            to="/verification/step5"
            className=" flex shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
          >
            Go Back
          </Link>

          <Link
            className=" flex ml-3 shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 md:py-4 md:text-lg md:px-10"
            to={!successfulDetection ? "#" : "/verification/success"}
          >
            {successfulDetection ? "Continue" : "Awaiting Form Completion"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Step6;
