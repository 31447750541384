import { ApolloError } from "@apollo/client";
import { useGetUserByEmailQuery, GetUserByEmailQuery } from "../../../../../shared/infrastructure/graphql/generated/types";
import { ArrayElement } from "../../../../../shared/internals/utils";

export interface HookResult {
  userByEmail?: ArrayElement<GetUserByEmailQuery['user']> 
  userByEmailError?: ApolloError;
  userByEmailLoading: boolean;
}

export const useUserByEmail = ({email}:{email:string}): HookResult => {
  const { data, error, loading } = useGetUserByEmailQuery({variables:{
    email: email
  }});

  return {
    userByEmail: data?.user[0],
    userByEmailError: error,
    userByEmailLoading: loading,
  };
};
