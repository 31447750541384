import { Fragment } from "react";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import {
  AnnotationIcon,
  ChatAlt2Icon,
  ChatAltIcon,
  CheckIcon,
  CloudUploadIcon,
  CogIcon,
  CurrencyDollarIcon,
  DocumentReportIcon,
  FingerPrintIcon,
  HeartIcon,
  InboxIcon,
  KeyIcon,
  LockClosedIcon,
  MenuIcon,
  PencilAltIcon,
  QuestionMarkCircleIcon,
  RefreshIcon,
  ReplyIcon,
  ShieldCheckIcon,
  SparklesIcon,
  SupportIcon,
  TrashIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ServerIcon } from "@heroicons/react/solid";
import { classNames } from "../../../../shared/internals/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../shared/infrastructure/firebase";
import { useUserByEmail } from "../graphql/hooks/useUserByEmail";
import Loading from "../../../../shared/internals/components/loading/Loading";
import Error from "../../../../shared/internals/components/error/Error";
import { Link } from "react-router-dom";

const stats = [
  { label: "Founded", value: "2012" },
  { label: "Community Members", value: "7,000+" },
  { label: "Support Provided", value: "$0.3M" },
  { label: "Global Outreach", value: "13" },
];

const features = [
  {
    name: "Access to Genuine Submissives",
    fee: "$800",
    target: "Professional Dommes",
    membership: "Level 2 Dommes",
    icon: FingerPrintIcon,
    text: " Our platform 'The Domme's Playground' allows pro-dommes to consistently find paying submissives in their area allowing pro-dommes to succeed on their terms.",
  },
  {
    name: "Community Platform",
    fee: "None",
    target: "All Dommes",
    membership: "Level 3 Dommes",
    icon: UserGroupIcon,
    text: "We have over 7,000 dommes on our main platform. A digital and in-person community that can provide support and relatability to other dominatrixes. ",
  },
  {
    name: "Breaking the Sub Training",
    fee: "$800",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: ShieldCheckIcon,
    text: "Years of research has helped us develop techniques to train a submissive and achieve full control and we have collated them into an online training platform so you can have the relationship you want.",
  },
  {
    name: "Feature Content Support ",
    fee: "$250 + Various",
    target: "Professional Dommes",
    membership: "Level 2 Dommes",
    icon: VideoCameraIcon,
    text: "Some of our dommes release content and we provide support through recording equipment, script creation, introductions to directors and producers, subsidized production costs and more. ",
  },
  {
    name: "Submissive Allocation Support",
    fee: "None",
    target: "LifeStyle Dommes",
    membership: "Level 2 Dommes",
    icon: SupportIcon,
    text: "For Domminatrixes currently without a submissive to serve them, we help find a discrete, appropriate submissive and provide ongoing financial and further support to faciliate domination on her terms.",
  },
  {
    name: "Support in Finding a Life Partner",
    fee: "$800",
    target: "LifeStyle Dommes",
    membership: "Level 2 Dommes",
    icon: KeyIcon,
    text: "Our 'LifeStyle Domme Platform' helps dominant ladies filter from a large pool of submissives to find a long term submissive partner of their choice. Thousands of couples have been matched in authentic female led relationships.",
  },
  {
    name: "Adhoc Financial Support",
    fee: "None",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: CurrencyDollarIcon,
    text: "We are strong believers in Female Supremacy. We believe every Lady should have access to a submissive that pleases them so we provide financial support to make this happen for dommes who do not have the means.",
  },
  {
    name: "Privacy & Security",
    fee: "None",
    target: "All Dommes",
    membership: "Level 2 Dommes",
    icon: LockClosedIcon,
    text: "We are absolutely committed to discretion and privacy. Our platforms are referral only and we make sure we vet all our members. We do not share any data and we make sure our members can continue dominating without worrying about public exposure.",
  },
];

const solutions = [
  {
    name: "Inbox",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: InboxIcon,
  },
  {
    name: "Messaging",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: AnnotationIcon,
  },
  {
    name: "Live Chat",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ChatAlt2Icon,
  },
  {
    name: "Knowledge Base",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: QuestionMarkCircleIcon,
  },
];

const privacy = [
  {
    name: "No Real Names Policy",
    description:
      "We encourage using an alias not just for your online profile but also if attending in person events or when being served by a submissive",
  },
  {
    name: "Strict No Recording Policy",
    description:
      "No photos, no videos and no audio recordings of our training content, our community platform, our matching platform or any in person interactions with submissives or dominants alike. ",
  },
  {
    name: "No Search Engines",
    description:
      "You won't find us on Google or on any other search engine or indeed any registry",
  },
  {
    name: "No Advertisments",
    description: "Advertisement is limited to word-of-mouth.",
  },
  {
    name: "No Data Sharing",
    description: "We do not share our data with any third party providers",
  },
  {
    name: "Data Minimisation",
    description:
      "We do not request any sensitive information unless expressly required and we immediately delete data if no longer needed",
  },
  {
    name: "Sophisticated Detection Capabilities",
    description:
      "Our mantra is `Trust but Verify`. We trust our members to maintain our confidence but we do not take our commmitments to privacy lightly as it is a key foundation for our success ",
  },
  {
    name: "Legal Enforcement",
    description:
      "In extreme circumstances, we pursue breaches of confidence legally.",
  },
];

const posts = [
  {
    title: "Get Verified",
    href: "#",
    category: {
      name: "Status: Unverified",
      href: "#",
      color: "bg-indigo-100 text-indigo-800",
    },
    button: "Verify Now",
    description:
      "This is a required step and should take no longer than 15 minutes.",
  },
  {
    title: "Access the Platform",
    href: "#",
    category: {
      name: "Waiting on Verification",
      href: "#",
      color: "bg-gray-100 text-gray-800",
    },
    button: "Access the Platform",
    description:
      "It is time for you to take control and live the life you want.",
  },
];

const faqs = [
  {
    question: "Are submissives allowed on the platform?",
    answer:
      "No. Our platform and services are only available to dominant women. A submissive male can be referred to us but they can only access a different more restricted version of our platform. They are not aware of what services we offer to dominant ladies and can only verify themselves and receive messages from dommes. We are against sharing our details with male submissives and request that you do not do so.",
  },
  {
    question: "This all sounds a bit unsafe",
    answer:
      "Although we are female supremacists, we absolutely care about safety, both in sexual health and in treatment of submissives. We have a set of guidelines as well as ethical behaviour we expect from our members. We do not advocate abuse but consensual and total domination.",
  },
  {
    question: "I am not comfortable.",
    answer:
      "We are really sorry to hear this, it is not our intention to make you uncomfortable. Our services and community is only useful for dominant ladies, if you are not a dominant lady, please do not progress further. Only continue, if you are sure that you are interested in one or more of our offerings.",
  },
  {
    question: "I have no experience as a domme.",
    answer:
      "There is no requirement to have had experience, our community of dommes includes dommes from all ages and experience. We have dedicated support to new dommes to help explore your dominant side.",
  },
  {
    question: "I can't afford the fees but want to explore my dominance",
    answer:
      "Unfortunately many of our services do require a fee, however we are very committed to ensuring every dominant lady has the pleasure of owning a submissive. In that vein, we provide financial support where needed as well as opening access to some training material and submissive matching services.",
  },
  {
    question:
      "Privacy is very important to me but I want to explore my dominance",
    answer:
      "We absolutely understand the need to protect your reputation and we are committed to doing so. Despite running over a decade, we can count on one hand the number of breaches that have affected the reputation of a domme in our community. Please be assured this is one of our top priorities.",
  },
  {
    question:
      "I am already in a committed relationship but I want to be more dominant",
    answer:
      "This is something which many dominant ladies in our community have stated. We believe the best relationship is one in which the male is submisive and obedient to his superior partner. In many ways, by establishing more dominance, your partner will grow to love it, feel a deeper sense of purpose in the relationship and feel less worry by relinquishing control. In our training we cover extensively on using your erotic power within a relationship to establish deeper and deeper control over your male. ",
  },
  {
    question: "Does the training actually work?",
    answer:
      "Our training is composed from years of research, best practices from Military Drill Training as well as hypnosis to allow you to establish deep psychological control. We are thorough believers in a Female-Led Society but we are completely against employing any of our techniques to manipulate non-consenting males into submission. These techniques are designed only for use against males who provide explicit or implicit consent for submission. We therefore regulate access to techniques designed to reduce males who are outwardly dominant or rank as Level 1 out of 5 in the submission scale. We must also emphasise that the success of techniques while high is not always guaranteed. Like in other arts, practice increases effectiveness   ",
  },
  {
    question:
      "What does it mean when you `Break` a submissive using the training",
    answer:
      "Breaking a submissive is something that we believe every domme should experience but its also something that requires delicate aftercare. With a broken sub, you can psychologically control it to no end. Imagine clicking your finger and having a sub unable to stop kissing your boots. Or perhaps being unable to stop crawling around like your personal dog. The level of control will be unlike anything else you've experienced",
  },
  {
    question: "What is a level 2 Domme?",
    answer:
      "There are different levels of access in our platform and services. A level 3 domme has more access to a level 2 domme such as access to training materials for breaking down a man who is less naturally submissive. if you are not verified, you are currently a level 1 Domme.  ",
  },
  // More questions...
];

export default function WelcomeNew() {
  const [user] = useAuthState(auth);

  const { userByEmail, userByEmailLoading, userByEmailError } = useUserByEmail({
    email: user!.email!, //TODO: NOT SAFE
  });

  if (userByEmailLoading) {
    return <Loading />;
  }

  if (userByEmailError) {
    return <Error></Error>;
  }

  return (
    <div className="pb-20">
      <main>
        {/* Hero section */}
        <div className="relative mt-10">
          <div className="absolute inset-x-0 bottom-0 h-1/2 " />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="https://99brides.com/wp-content/uploads/2022/10/physically-fit-woman-with-long-hair-is-standing-crossed-her-hands-dark-background-768x512.jpg"
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-pink-700 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Take control of your</span>
                  <span className="block text-pink-200">relationships</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-pink-200 sm:max-w-3xl">
                  If you have ever wanted to make a man your obedient pet, then
                  you have reached the right place
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a
                      href="#started"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 bg-white hover:bg-pink-50 sm:px-8"
                    >
                      Get started
                    </a>
                    <a
                      href="#services"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Empower Section */}
        <div className="relative mt-20">
          <div className="">
            <div className="relative  mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              {/* Content area */}
              <div className="">
                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                  On a mission to empower women
                </h2>
                <div className="mt-6 text-gray-500 space-y-6">
                  <p className="text-lg">
                    We are the worlds leading Private Fetish Service Provider
                    dedicated to the International Fetish Scene. We are run by
                    Women, Owned by Women, For the Benefit of Women.
                  </p>
                  <p className="text-base leading-7">
                    To put simply, we are a group of dommes committed to helping
                    other dommes prosper! Mandy and Lisa, our founders, have
                    been in the Fetish Community for over 35 years. They
                    realised that there wasn't much support offered to dommes.
                    Directors and Producers were heavy handed gatekeepers.
                    Genuine Subs were difficult to reach consistently and the
                    growing independence dommes accessed was very much a
                    double-edged sword. To suceed, genuine Dommes, more often
                    than not, had to sacrifice their terms and amuse male
                    fetishes and self-serving sycophants. We decided to do
                    something about it.
                  </p>
                  <p className="text-base leading-7">
                    Today, we have built a vibrant community of dommes and offer
                    a range of services. From helping dominant ladies find life
                    partners for a female-led relationship, subsidizing
                    production costs for independent pro-domme creators and
                    providing training on how to make a submissive man addicted
                    and entirely obedient. As the Fetish landscape changes, our
                    role will hopefully evolve over time. But one thing will
                    remain - our commitment to dominant women and our advocation
                    for female-led relationships. It's our core value, it's what
                    we really care about.{" "}
                    <span className="text-pink-300">
                      We believe every woman should have men in her life who
                      serve her not only as their Queen, but their Superior,
                      with nothing but the reward of attention, however fleeting
                      it may be, and with of course a future of continued labour
                      and servitude, all for her benefit and gain.{" "}
                    </span>
                  </p>
                  <p className="text-base leading-7">
                    We thank our members for their continued support and we
                    thank our investors for promoting our vision of a discrete,
                    fetish community built around the dominant woman. It has
                    been a difficult journey building an authentic community
                    without sacrificing the demands for absolute discretion and
                    quality, but a journey worth every dominant who now enjoys
                    the pleasure of truly subjugated pets and the peace of mind
                    of financial freedom without sacrifice. We are sure that our
                    contribution to the Fetish community will only rise and we
                    hope you can join us on this journey.
                  </p>
                </div>
              </div>

              {/* Stats section */}
              <div className="mt-10">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                  {stats.map((stat) => (
                    <div
                      key={stat.label}
                      className="border-t-2 border-gray-100 pt-6"
                    >
                      <dt className="text-base font-medium text-gray-500">
                        {stat.label}
                      </dt>
                      <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))}
                </dl>
                <div className="mt-10"></div>
              </div>
            </div>
          </div>
        </div>

        {/* services section */}
        <div
          id="services"
          className="relative bg-white py-16 sm:py-24 lg:py-32"
        >
          <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
              Our Services
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              How we help
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              We help in many ways, but the key thing to remember is we are
              committed to helping other dommes prosper while they maintain
              their privacy and independence
            </p>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {features.map((feature) => (
                  <div key={feature.name} className="pt-6">
                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                      <div className="-mt-6">
                        <div>
                          <span className="inline-flex items-center justify-center p-3 bg-pink-500 rounded-md shadow-lg">
                            <feature.icon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                          {feature.name}
                        </h3>
                        <p className="text-sm font-bold text-gray-400 italic">
                          Required Fee: {feature.fee}
                        </p>
                        <p className="text-sm font-bold text-gray-400 italic">
                          Target: {feature.target}
                        </p>
                        <p className="text-sm font-bold text-gray-400 italic">
                          Membership Level: {feature.membership}
                        </p>
                        <p className="mt-5 text-base text-gray-500">
                          {feature.text}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Privacy */}
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              We are committed to Privacy
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              We are private for a reason. Not all of our dommes are comfortable
              with their friends, family, colleagues or the outside world
              knowing that they've turned their husband into their plaything or
              that they want to find a local submissive to fuck with a strapon.
              For those dommes, who wish to keep that side of them completely
              private, rest assured that we are committed to protecting your
              reputation and take privacy extremely seriously.
            </p>
          </div>
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
            {privacy.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* get Started section */}
        <div
          id="started"
          className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
        >
          <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div>
              <h2 className="text-3xl flex flex-col justify-center items-center tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                Next Steps
              </h2>
              <p className="mt-3 text-xl flex flex-col items-center text-gray-500 sm:mt-4">
                In order to access our platforms, training and financial &
                additional support, we first need to verify you. We apologise
                for this process but this is necessary to protect our platform
                from malicious individuals. We have designed it to be smooth and
                simple and as minimally invasive as possible.
              </p>
            </div>
            <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
              <div>
                <div>
                  <a className="inline-block">
                    <span
                      className={classNames(
                        "bg-indigo-100 text-indigo-800",
                        "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
                      )}
                    >
                      Status:{" "}
                      {userByEmail?.is_verified ? "Verified" : "Unverified"}
                    </span>
                  </a>
                </div>
                <a className="block mt-4">
                  <p className="text-xl font-semibold text-gray-900">
                    Get Verified
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    This is a required step and should take no longer than 15
                    minutes.
                  </p>
                </a>
                <div className="mt-6 flex items-center"></div>
                {userByEmail?.is_verified ? (
                  <div className="bg-gray-100">You are verified</div>
                ) : (
                  <Link
                    to="/verification/step1"
                    className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Verify Now
                  </Link>
                )}
              </div>
              <div>
                <div>
                  <a className="inline-block">
                    <span
                      className={classNames(
                        "bg-gray-100 text-gray-800",
                        "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
                      )}
                    >
                      {userByEmail?.is_verified
                        ? "Get Started"
                        : "Waiting on Verification"}
                    </span>
                  </a>
                </div>
                <a className="block mt-4">
                  <p className="text-xl font-semibold text-gray-900">
                    Access the Platform
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Find your ideal sub, Learn how to break down submissives,
                    access the support you need and join our community!
                  </p>
                </a>
                <div className="mt-6 flex items-center"></div>

                {userByEmail?.is_verified ? (
                  <button
                    type="button"
                    className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    Access the Platform
                  </button>
                ) : (
                  <div className="bg-gray-100">Verification is Required </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/*fAQ section */}
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </main>
    </div>
  );
}
