import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "images" */
export type Images = {
  __typename?: 'images';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "images" */
export type Images_Aggregate = {
  __typename?: 'images_aggregate';
  aggregate?: Maybe<Images_Aggregate_Fields>;
  nodes: Array<Images>;
};

/** aggregate fields of "images" */
export type Images_Aggregate_Fields = {
  __typename?: 'images_aggregate_fields';
  avg?: Maybe<Images_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Images_Max_Fields>;
  min?: Maybe<Images_Min_Fields>;
  stddev?: Maybe<Images_Stddev_Fields>;
  stddev_pop?: Maybe<Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Images_Stddev_Samp_Fields>;
  sum?: Maybe<Images_Sum_Fields>;
  var_pop?: Maybe<Images_Var_Pop_Fields>;
  var_samp?: Maybe<Images_Var_Samp_Fields>;
  variance?: Maybe<Images_Variance_Fields>;
};


/** aggregate fields of "images" */
export type Images_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Images_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Images_Avg_Fields = {
  __typename?: 'images_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "images". All fields are combined with a logical 'AND'. */
export type Images_Bool_Exp = {
  _and?: InputMaybe<Array<Images_Bool_Exp>>;
  _not?: InputMaybe<Images_Bool_Exp>;
  _or?: InputMaybe<Array<Images_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "images" */
export enum Images_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImagesPkey = 'images_pkey'
}

/** input type for incrementing numeric columns in table "images" */
export type Images_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "images" */
export type Images_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Images_Max_Fields = {
  __typename?: 'images_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Images_Min_Fields = {
  __typename?: 'images_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "images" */
export type Images_Mutation_Response = {
  __typename?: 'images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Images>;
};

/** on_conflict condition type for table "images" */
export type Images_On_Conflict = {
  constraint: Images_Constraint;
  update_columns?: Array<Images_Update_Column>;
  where?: InputMaybe<Images_Bool_Exp>;
};

/** Ordering options when selecting data from "images". */
export type Images_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: images */
export type Images_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "images" */
export enum Images_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "images" */
export type Images_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Images_Stddev_Fields = {
  __typename?: 'images_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Images_Stddev_Pop_Fields = {
  __typename?: 'images_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Images_Stddev_Samp_Fields = {
  __typename?: 'images_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "images" */
export type Images_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Images_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Images_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Images_Sum_Fields = {
  __typename?: 'images_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "images" */
export enum Images_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Images_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Images_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Images_Set_Input>;
  /** filter the rows which have to be updated */
  where: Images_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Images_Var_Pop_Fields = {
  __typename?: 'images_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Images_Var_Samp_Fields = {
  __typename?: 'images_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Images_Variance_Fields = {
  __typename?: 'images_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "initial_answers" */
export type Initial_Answers = {
  __typename?: 'initial_answers';
  accomodation_type?: Maybe<Scalars['String']>;
  age_bracket?: Maybe<Scalars['String']>;
  control_level?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  discretion_importance?: Maybe<Scalars['String']>;
  dominance_experience?: Maybe<Scalars['String']>;
  dominance_level?: Maybe<Scalars['String']>;
  domination_style?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employment_status?: Maybe<Scalars['String']>;
  fetishes?: Maybe<Scalars['String']>;
  financial_support?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  nearest_location_hub?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  relationship_location_preference?: Maybe<Scalars['String']>;
  relationship_status?: Maybe<Scalars['String']>;
  relationship_type_preference?: Maybe<Scalars['String']>;
  training_access?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "initial_answers" */
export type Initial_Answers_Aggregate = {
  __typename?: 'initial_answers_aggregate';
  aggregate?: Maybe<Initial_Answers_Aggregate_Fields>;
  nodes: Array<Initial_Answers>;
};

/** aggregate fields of "initial_answers" */
export type Initial_Answers_Aggregate_Fields = {
  __typename?: 'initial_answers_aggregate_fields';
  avg?: Maybe<Initial_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Initial_Answers_Max_Fields>;
  min?: Maybe<Initial_Answers_Min_Fields>;
  stddev?: Maybe<Initial_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Initial_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Initial_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Initial_Answers_Sum_Fields>;
  var_pop?: Maybe<Initial_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Initial_Answers_Var_Samp_Fields>;
  variance?: Maybe<Initial_Answers_Variance_Fields>;
};


/** aggregate fields of "initial_answers" */
export type Initial_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Initial_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Initial_Answers_Avg_Fields = {
  __typename?: 'initial_answers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "initial_answers". All fields are combined with a logical 'AND'. */
export type Initial_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Initial_Answers_Bool_Exp>>;
  _not?: InputMaybe<Initial_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Initial_Answers_Bool_Exp>>;
  accomodation_type?: InputMaybe<String_Comparison_Exp>;
  age_bracket?: InputMaybe<String_Comparison_Exp>;
  control_level?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discretion_importance?: InputMaybe<String_Comparison_Exp>;
  dominance_experience?: InputMaybe<String_Comparison_Exp>;
  dominance_level?: InputMaybe<String_Comparison_Exp>;
  domination_style?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employment_status?: InputMaybe<String_Comparison_Exp>;
  fetishes?: InputMaybe<String_Comparison_Exp>;
  financial_support?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nearest_location_hub?: InputMaybe<String_Comparison_Exp>;
  race?: InputMaybe<String_Comparison_Exp>;
  relationship_location_preference?: InputMaybe<String_Comparison_Exp>;
  relationship_status?: InputMaybe<String_Comparison_Exp>;
  relationship_type_preference?: InputMaybe<String_Comparison_Exp>;
  training_access?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "initial_answers" */
export enum Initial_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  InitialAnswersPkey = 'initial_answers_pkey'
}

/** input type for incrementing numeric columns in table "initial_answers" */
export type Initial_Answers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "initial_answers" */
export type Initial_Answers_Insert_Input = {
  accomodation_type?: InputMaybe<Scalars['String']>;
  age_bracket?: InputMaybe<Scalars['String']>;
  control_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discretion_importance?: InputMaybe<Scalars['String']>;
  dominance_experience?: InputMaybe<Scalars['String']>;
  dominance_level?: InputMaybe<Scalars['String']>;
  domination_style?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Scalars['String']>;
  fetishes?: InputMaybe<Scalars['String']>;
  financial_support?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nearest_location_hub?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  relationship_location_preference?: InputMaybe<Scalars['String']>;
  relationship_status?: InputMaybe<Scalars['String']>;
  relationship_type_preference?: InputMaybe<Scalars['String']>;
  training_access?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Initial_Answers_Max_Fields = {
  __typename?: 'initial_answers_max_fields';
  accomodation_type?: Maybe<Scalars['String']>;
  age_bracket?: Maybe<Scalars['String']>;
  control_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discretion_importance?: Maybe<Scalars['String']>;
  dominance_experience?: Maybe<Scalars['String']>;
  dominance_level?: Maybe<Scalars['String']>;
  domination_style?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  fetishes?: Maybe<Scalars['String']>;
  financial_support?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nearest_location_hub?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  relationship_location_preference?: Maybe<Scalars['String']>;
  relationship_status?: Maybe<Scalars['String']>;
  relationship_type_preference?: Maybe<Scalars['String']>;
  training_access?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Initial_Answers_Min_Fields = {
  __typename?: 'initial_answers_min_fields';
  accomodation_type?: Maybe<Scalars['String']>;
  age_bracket?: Maybe<Scalars['String']>;
  control_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discretion_importance?: Maybe<Scalars['String']>;
  dominance_experience?: Maybe<Scalars['String']>;
  dominance_level?: Maybe<Scalars['String']>;
  domination_style?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  fetishes?: Maybe<Scalars['String']>;
  financial_support?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  nearest_location_hub?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  relationship_location_preference?: Maybe<Scalars['String']>;
  relationship_status?: Maybe<Scalars['String']>;
  relationship_type_preference?: Maybe<Scalars['String']>;
  training_access?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "initial_answers" */
export type Initial_Answers_Mutation_Response = {
  __typename?: 'initial_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Initial_Answers>;
};

/** on_conflict condition type for table "initial_answers" */
export type Initial_Answers_On_Conflict = {
  constraint: Initial_Answers_Constraint;
  update_columns?: Array<Initial_Answers_Update_Column>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "initial_answers". */
export type Initial_Answers_Order_By = {
  accomodation_type?: InputMaybe<Order_By>;
  age_bracket?: InputMaybe<Order_By>;
  control_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discretion_importance?: InputMaybe<Order_By>;
  dominance_experience?: InputMaybe<Order_By>;
  dominance_level?: InputMaybe<Order_By>;
  domination_style?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employment_status?: InputMaybe<Order_By>;
  fetishes?: InputMaybe<Order_By>;
  financial_support?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nearest_location_hub?: InputMaybe<Order_By>;
  race?: InputMaybe<Order_By>;
  relationship_location_preference?: InputMaybe<Order_By>;
  relationship_status?: InputMaybe<Order_By>;
  relationship_type_preference?: InputMaybe<Order_By>;
  training_access?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: initial_answers */
export type Initial_Answers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "initial_answers" */
export enum Initial_Answers_Select_Column {
  /** column name */
  AccomodationType = 'accomodation_type',
  /** column name */
  AgeBracket = 'age_bracket',
  /** column name */
  ControlLevel = 'control_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscretionImportance = 'discretion_importance',
  /** column name */
  DominanceExperience = 'dominance_experience',
  /** column name */
  DominanceLevel = 'dominance_level',
  /** column name */
  DominationStyle = 'domination_style',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  Fetishes = 'fetishes',
  /** column name */
  FinancialSupport = 'financial_support',
  /** column name */
  Id = 'id',
  /** column name */
  NearestLocationHub = 'nearest_location_hub',
  /** column name */
  Race = 'race',
  /** column name */
  RelationshipLocationPreference = 'relationship_location_preference',
  /** column name */
  RelationshipStatus = 'relationship_status',
  /** column name */
  RelationshipTypePreference = 'relationship_type_preference',
  /** column name */
  TrainingAccess = 'training_access',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "initial_answers" */
export type Initial_Answers_Set_Input = {
  accomodation_type?: InputMaybe<Scalars['String']>;
  age_bracket?: InputMaybe<Scalars['String']>;
  control_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discretion_importance?: InputMaybe<Scalars['String']>;
  dominance_experience?: InputMaybe<Scalars['String']>;
  dominance_level?: InputMaybe<Scalars['String']>;
  domination_style?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Scalars['String']>;
  fetishes?: InputMaybe<Scalars['String']>;
  financial_support?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nearest_location_hub?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  relationship_location_preference?: InputMaybe<Scalars['String']>;
  relationship_status?: InputMaybe<Scalars['String']>;
  relationship_type_preference?: InputMaybe<Scalars['String']>;
  training_access?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Initial_Answers_Stddev_Fields = {
  __typename?: 'initial_answers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Initial_Answers_Stddev_Pop_Fields = {
  __typename?: 'initial_answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Initial_Answers_Stddev_Samp_Fields = {
  __typename?: 'initial_answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "initial_answers" */
export type Initial_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Initial_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Initial_Answers_Stream_Cursor_Value_Input = {
  accomodation_type?: InputMaybe<Scalars['String']>;
  age_bracket?: InputMaybe<Scalars['String']>;
  control_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discretion_importance?: InputMaybe<Scalars['String']>;
  dominance_experience?: InputMaybe<Scalars['String']>;
  dominance_level?: InputMaybe<Scalars['String']>;
  domination_style?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Scalars['String']>;
  fetishes?: InputMaybe<Scalars['String']>;
  financial_support?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  nearest_location_hub?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  relationship_location_preference?: InputMaybe<Scalars['String']>;
  relationship_status?: InputMaybe<Scalars['String']>;
  relationship_type_preference?: InputMaybe<Scalars['String']>;
  training_access?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Initial_Answers_Sum_Fields = {
  __typename?: 'initial_answers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "initial_answers" */
export enum Initial_Answers_Update_Column {
  /** column name */
  AccomodationType = 'accomodation_type',
  /** column name */
  AgeBracket = 'age_bracket',
  /** column name */
  ControlLevel = 'control_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscretionImportance = 'discretion_importance',
  /** column name */
  DominanceExperience = 'dominance_experience',
  /** column name */
  DominanceLevel = 'dominance_level',
  /** column name */
  DominationStyle = 'domination_style',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  Fetishes = 'fetishes',
  /** column name */
  FinancialSupport = 'financial_support',
  /** column name */
  Id = 'id',
  /** column name */
  NearestLocationHub = 'nearest_location_hub',
  /** column name */
  Race = 'race',
  /** column name */
  RelationshipLocationPreference = 'relationship_location_preference',
  /** column name */
  RelationshipStatus = 'relationship_status',
  /** column name */
  RelationshipTypePreference = 'relationship_type_preference',
  /** column name */
  TrainingAccess = 'training_access',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Initial_Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Initial_Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Initial_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Initial_Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Initial_Answers_Var_Pop_Fields = {
  __typename?: 'initial_answers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Initial_Answers_Var_Samp_Fields = {
  __typename?: 'initial_answers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Initial_Answers_Variance_Fields = {
  __typename?: 'initial_answers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "images" */
  delete_images?: Maybe<Images_Mutation_Response>;
  /** delete single row from the table: "images" */
  delete_images_by_pk?: Maybe<Images>;
  /** delete data from the table: "initial_answers" */
  delete_initial_answers?: Maybe<Initial_Answers_Mutation_Response>;
  /** delete single row from the table: "initial_answers" */
  delete_initial_answers_by_pk?: Maybe<Initial_Answers>;
  /** delete data from the table: "second_responses" */
  delete_second_responses?: Maybe<Second_Responses_Mutation_Response>;
  /** delete single row from the table: "second_responses" */
  delete_second_responses_by_pk?: Maybe<Second_Responses>;
  /** delete data from the table: "test" */
  delete_test?: Maybe<Test_Mutation_Response>;
  /** delete single row from the table: "test" */
  delete_test_by_pk?: Maybe<Test>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** insert data into the table: "images" */
  insert_images?: Maybe<Images_Mutation_Response>;
  /** insert a single row into the table: "images" */
  insert_images_one?: Maybe<Images>;
  /** insert data into the table: "initial_answers" */
  insert_initial_answers?: Maybe<Initial_Answers_Mutation_Response>;
  /** insert a single row into the table: "initial_answers" */
  insert_initial_answers_one?: Maybe<Initial_Answers>;
  /** insert data into the table: "second_responses" */
  insert_second_responses?: Maybe<Second_Responses_Mutation_Response>;
  /** insert a single row into the table: "second_responses" */
  insert_second_responses_one?: Maybe<Second_Responses>;
  /** insert data into the table: "test" */
  insert_test?: Maybe<Test_Mutation_Response>;
  /** insert a single row into the table: "test" */
  insert_test_one?: Maybe<Test>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** update data of the table: "images" */
  update_images?: Maybe<Images_Mutation_Response>;
  /** update single row of the table: "images" */
  update_images_by_pk?: Maybe<Images>;
  /** update multiples rows of table: "images" */
  update_images_many?: Maybe<Array<Maybe<Images_Mutation_Response>>>;
  /** update data of the table: "initial_answers" */
  update_initial_answers?: Maybe<Initial_Answers_Mutation_Response>;
  /** update single row of the table: "initial_answers" */
  update_initial_answers_by_pk?: Maybe<Initial_Answers>;
  /** update multiples rows of table: "initial_answers" */
  update_initial_answers_many?: Maybe<Array<Maybe<Initial_Answers_Mutation_Response>>>;
  /** update data of the table: "second_responses" */
  update_second_responses?: Maybe<Second_Responses_Mutation_Response>;
  /** update single row of the table: "second_responses" */
  update_second_responses_by_pk?: Maybe<Second_Responses>;
  /** update multiples rows of table: "second_responses" */
  update_second_responses_many?: Maybe<Array<Maybe<Second_Responses_Mutation_Response>>>;
  /** update data of the table: "test" */
  update_test?: Maybe<Test_Mutation_Response>;
  /** update single row of the table: "test" */
  update_test_by_pk?: Maybe<Test>;
  /** update multiples rows of table: "test" */
  update_test_many?: Maybe<Array<Maybe<Test_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_ImagesArgs = {
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Images_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Initial_AnswersArgs = {
  where: Initial_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Initial_Answers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Second_ResponsesArgs = {
  where: Second_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Second_Responses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TestArgs = {
  where: Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_ImagesArgs = {
  objects: Array<Images_Insert_Input>;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Images_OneArgs = {
  object: Images_Insert_Input;
  on_conflict?: InputMaybe<Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Initial_AnswersArgs = {
  objects: Array<Initial_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Initial_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Initial_Answers_OneArgs = {
  object: Initial_Answers_Insert_Input;
  on_conflict?: InputMaybe<Initial_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Second_ResponsesArgs = {
  objects: Array<Second_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Second_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Second_Responses_OneArgs = {
  object: Second_Responses_Insert_Input;
  on_conflict?: InputMaybe<Second_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TestArgs = {
  objects: Array<Test_Insert_Input>;
  on_conflict?: InputMaybe<Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_OneArgs = {
  object: Test_Insert_Input;
  on_conflict?: InputMaybe<Test_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_ImagesArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  where: Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Images_By_PkArgs = {
  _inc?: InputMaybe<Images_Inc_Input>;
  _set?: InputMaybe<Images_Set_Input>;
  pk_columns: Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Images_ManyArgs = {
  updates: Array<Images_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Initial_AnswersArgs = {
  _inc?: InputMaybe<Initial_Answers_Inc_Input>;
  _set?: InputMaybe<Initial_Answers_Set_Input>;
  where: Initial_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Initial_Answers_By_PkArgs = {
  _inc?: InputMaybe<Initial_Answers_Inc_Input>;
  _set?: InputMaybe<Initial_Answers_Set_Input>;
  pk_columns: Initial_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Initial_Answers_ManyArgs = {
  updates: Array<Initial_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Second_ResponsesArgs = {
  _inc?: InputMaybe<Second_Responses_Inc_Input>;
  _set?: InputMaybe<Second_Responses_Set_Input>;
  where: Second_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Second_Responses_By_PkArgs = {
  _inc?: InputMaybe<Second_Responses_Inc_Input>;
  _set?: InputMaybe<Second_Responses_Set_Input>;
  pk_columns: Second_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Second_Responses_ManyArgs = {
  updates: Array<Second_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TestArgs = {
  _inc?: InputMaybe<Test_Inc_Input>;
  _set?: InputMaybe<Test_Set_Input>;
  where: Test_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_By_PkArgs = {
  _inc?: InputMaybe<Test_Inc_Input>;
  _set?: InputMaybe<Test_Set_Input>;
  pk_columns: Test_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_ManyArgs = {
  updates: Array<Test_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table: "initial_answers" */
  initial_answers: Array<Initial_Answers>;
  /** fetch aggregated fields from the table: "initial_answers" */
  initial_answers_aggregate: Initial_Answers_Aggregate;
  /** fetch data from the table: "initial_answers" using primary key columns */
  initial_answers_by_pk?: Maybe<Initial_Answers>;
  /** fetch data from the table: "second_responses" */
  second_responses: Array<Second_Responses>;
  /** fetch aggregated fields from the table: "second_responses" */
  second_responses_aggregate: Second_Responses_Aggregate;
  /** fetch data from the table: "second_responses" using primary key columns */
  second_responses_by_pk?: Maybe<Second_Responses>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch aggregated fields from the table: "test" */
  test_aggregate: Test_Aggregate;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type Query_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Query_RootImages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInitial_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Initial_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Answers_Order_By>>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};


export type Query_RootInitial_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Initial_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Answers_Order_By>>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};


export type Query_RootInitial_Answers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSecond_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Second_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Second_Responses_Order_By>>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};


export type Query_RootSecond_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Second_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Second_Responses_Order_By>>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};


export type Query_RootSecond_Responses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Query_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "second_responses" */
export type Second_Responses = {
  __typename?: 'second_responses';
  clinic_visit_note?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_sexually_healthy?: Maybe<Scalars['String']>;
  last_clinic_visit?: Maybe<Scalars['String']>;
  sexual_health_note?: Maybe<Scalars['String']>;
  uploaded_file: Scalars['String'];
};

/** aggregated selection of "second_responses" */
export type Second_Responses_Aggregate = {
  __typename?: 'second_responses_aggregate';
  aggregate?: Maybe<Second_Responses_Aggregate_Fields>;
  nodes: Array<Second_Responses>;
};

/** aggregate fields of "second_responses" */
export type Second_Responses_Aggregate_Fields = {
  __typename?: 'second_responses_aggregate_fields';
  avg?: Maybe<Second_Responses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Second_Responses_Max_Fields>;
  min?: Maybe<Second_Responses_Min_Fields>;
  stddev?: Maybe<Second_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Second_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Second_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Second_Responses_Sum_Fields>;
  var_pop?: Maybe<Second_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Second_Responses_Var_Samp_Fields>;
  variance?: Maybe<Second_Responses_Variance_Fields>;
};


/** aggregate fields of "second_responses" */
export type Second_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Second_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Second_Responses_Avg_Fields = {
  __typename?: 'second_responses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "second_responses". All fields are combined with a logical 'AND'. */
export type Second_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Second_Responses_Bool_Exp>>;
  _not?: InputMaybe<Second_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Second_Responses_Bool_Exp>>;
  clinic_visit_note?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  extension?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_sexually_healthy?: InputMaybe<String_Comparison_Exp>;
  last_clinic_visit?: InputMaybe<String_Comparison_Exp>;
  sexual_health_note?: InputMaybe<String_Comparison_Exp>;
  uploaded_file?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "second_responses" */
export enum Second_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  SecondResponsesPkey = 'second_responses_pkey'
}

/** input type for incrementing numeric columns in table "second_responses" */
export type Second_Responses_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "second_responses" */
export type Second_Responses_Insert_Input = {
  clinic_visit_note?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_sexually_healthy?: InputMaybe<Scalars['String']>;
  last_clinic_visit?: InputMaybe<Scalars['String']>;
  sexual_health_note?: InputMaybe<Scalars['String']>;
  uploaded_file?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Second_Responses_Max_Fields = {
  __typename?: 'second_responses_max_fields';
  clinic_visit_note?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_sexually_healthy?: Maybe<Scalars['String']>;
  last_clinic_visit?: Maybe<Scalars['String']>;
  sexual_health_note?: Maybe<Scalars['String']>;
  uploaded_file?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Second_Responses_Min_Fields = {
  __typename?: 'second_responses_min_fields';
  clinic_visit_note?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_sexually_healthy?: Maybe<Scalars['String']>;
  last_clinic_visit?: Maybe<Scalars['String']>;
  sexual_health_note?: Maybe<Scalars['String']>;
  uploaded_file?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "second_responses" */
export type Second_Responses_Mutation_Response = {
  __typename?: 'second_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Second_Responses>;
};

/** on_conflict condition type for table "second_responses" */
export type Second_Responses_On_Conflict = {
  constraint: Second_Responses_Constraint;
  update_columns?: Array<Second_Responses_Update_Column>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "second_responses". */
export type Second_Responses_Order_By = {
  clinic_visit_note?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  extension?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_sexually_healthy?: InputMaybe<Order_By>;
  last_clinic_visit?: InputMaybe<Order_By>;
  sexual_health_note?: InputMaybe<Order_By>;
  uploaded_file?: InputMaybe<Order_By>;
};

/** primary key columns input for table: second_responses */
export type Second_Responses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "second_responses" */
export enum Second_Responses_Select_Column {
  /** column name */
  ClinicVisitNote = 'clinic_visit_note',
  /** column name */
  Email = 'email',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  IsSexuallyHealthy = 'is_sexually_healthy',
  /** column name */
  LastClinicVisit = 'last_clinic_visit',
  /** column name */
  SexualHealthNote = 'sexual_health_note',
  /** column name */
  UploadedFile = 'uploaded_file'
}

/** input type for updating data in table "second_responses" */
export type Second_Responses_Set_Input = {
  clinic_visit_note?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_sexually_healthy?: InputMaybe<Scalars['String']>;
  last_clinic_visit?: InputMaybe<Scalars['String']>;
  sexual_health_note?: InputMaybe<Scalars['String']>;
  uploaded_file?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Second_Responses_Stddev_Fields = {
  __typename?: 'second_responses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Second_Responses_Stddev_Pop_Fields = {
  __typename?: 'second_responses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Second_Responses_Stddev_Samp_Fields = {
  __typename?: 'second_responses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "second_responses" */
export type Second_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Second_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Second_Responses_Stream_Cursor_Value_Input = {
  clinic_visit_note?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_sexually_healthy?: InputMaybe<Scalars['String']>;
  last_clinic_visit?: InputMaybe<Scalars['String']>;
  sexual_health_note?: InputMaybe<Scalars['String']>;
  uploaded_file?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Second_Responses_Sum_Fields = {
  __typename?: 'second_responses_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "second_responses" */
export enum Second_Responses_Update_Column {
  /** column name */
  ClinicVisitNote = 'clinic_visit_note',
  /** column name */
  Email = 'email',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  IsSexuallyHealthy = 'is_sexually_healthy',
  /** column name */
  LastClinicVisit = 'last_clinic_visit',
  /** column name */
  SexualHealthNote = 'sexual_health_note',
  /** column name */
  UploadedFile = 'uploaded_file'
}

export type Second_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Second_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Second_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Second_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Second_Responses_Var_Pop_Fields = {
  __typename?: 'second_responses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Second_Responses_Var_Samp_Fields = {
  __typename?: 'second_responses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Second_Responses_Variance_Fields = {
  __typename?: 'second_responses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "images" */
  images: Array<Images>;
  /** fetch aggregated fields from the table: "images" */
  images_aggregate: Images_Aggregate;
  /** fetch data from the table: "images" using primary key columns */
  images_by_pk?: Maybe<Images>;
  /** fetch data from the table in a streaming manner: "images" */
  images_stream: Array<Images>;
  /** fetch data from the table: "initial_answers" */
  initial_answers: Array<Initial_Answers>;
  /** fetch aggregated fields from the table: "initial_answers" */
  initial_answers_aggregate: Initial_Answers_Aggregate;
  /** fetch data from the table: "initial_answers" using primary key columns */
  initial_answers_by_pk?: Maybe<Initial_Answers>;
  /** fetch data from the table in a streaming manner: "initial_answers" */
  initial_answers_stream: Array<Initial_Answers>;
  /** fetch data from the table: "second_responses" */
  second_responses: Array<Second_Responses>;
  /** fetch aggregated fields from the table: "second_responses" */
  second_responses_aggregate: Second_Responses_Aggregate;
  /** fetch data from the table: "second_responses" using primary key columns */
  second_responses_by_pk?: Maybe<Second_Responses>;
  /** fetch data from the table in a streaming manner: "second_responses" */
  second_responses_stream: Array<Second_Responses>;
  /** fetch data from the table: "test" */
  test: Array<Test>;
  /** fetch aggregated fields from the table: "test" */
  test_aggregate: Test_Aggregate;
  /** fetch data from the table: "test" using primary key columns */
  test_by_pk?: Maybe<Test>;
  /** fetch data from the table in a streaming manner: "test" */
  test_stream: Array<Test>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootImagesArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Images_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Images_Order_By>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootImages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootImages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Images_Stream_Cursor_Input>>;
  where?: InputMaybe<Images_Bool_Exp>;
};


export type Subscription_RootInitial_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Initial_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Answers_Order_By>>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};


export type Subscription_RootInitial_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Initial_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Initial_Answers_Order_By>>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};


export type Subscription_RootInitial_Answers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInitial_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Initial_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Initial_Answers_Bool_Exp>;
};


export type Subscription_RootSecond_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Second_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Second_Responses_Order_By>>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};


export type Subscription_RootSecond_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Second_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Second_Responses_Order_By>>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};


export type Subscription_RootSecond_Responses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSecond_Responses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Second_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Second_Responses_Bool_Exp>;
};


export type Subscription_RootTestArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Test_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Test_Order_By>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootTest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Test_Stream_Cursor_Input>>;
  where?: InputMaybe<Test_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** columns and relationships of "test" */
export type Test = {
  __typename?: 'test';
  id: Scalars['Int'];
};

/** aggregated selection of "test" */
export type Test_Aggregate = {
  __typename?: 'test_aggregate';
  aggregate?: Maybe<Test_Aggregate_Fields>;
  nodes: Array<Test>;
};

/** aggregate fields of "test" */
export type Test_Aggregate_Fields = {
  __typename?: 'test_aggregate_fields';
  avg?: Maybe<Test_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Max_Fields>;
  min?: Maybe<Test_Min_Fields>;
  stddev?: Maybe<Test_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Sum_Fields>;
  var_pop?: Maybe<Test_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Var_Samp_Fields>;
  variance?: Maybe<Test_Variance_Fields>;
};


/** aggregate fields of "test" */
export type Test_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Test_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Test_Avg_Fields = {
  __typename?: 'test_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "test". All fields are combined with a logical 'AND'. */
export type Test_Bool_Exp = {
  _and?: InputMaybe<Array<Test_Bool_Exp>>;
  _not?: InputMaybe<Test_Bool_Exp>;
  _or?: InputMaybe<Array<Test_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "test" */
export enum Test_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestPkey = 'test_pkey'
}

/** input type for incrementing numeric columns in table "test" */
export type Test_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "test" */
export type Test_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Test_Max_Fields = {
  __typename?: 'test_max_fields';
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Test_Min_Fields = {
  __typename?: 'test_min_fields';
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "test" */
export type Test_Mutation_Response = {
  __typename?: 'test_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test>;
};

/** on_conflict condition type for table "test" */
export type Test_On_Conflict = {
  constraint: Test_Constraint;
  update_columns?: Array<Test_Update_Column>;
  where?: InputMaybe<Test_Bool_Exp>;
};

/** Ordering options when selecting data from "test". */
export type Test_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: test */
export type Test_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "test" */
export enum Test_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "test" */
export type Test_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Test_Stddev_Fields = {
  __typename?: 'test_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Test_Stddev_Pop_Fields = {
  __typename?: 'test_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Test_Stddev_Samp_Fields = {
  __typename?: 'test_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "test" */
export type Test_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Test_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Test_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Test_Sum_Fields = {
  __typename?: 'test_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "test" */
export enum Test_Update_Column {
  /** column name */
  Id = 'id'
}

export type Test_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Test_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Test_Set_Input>;
  /** filter the rows which have to be updated */
  where: Test_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Test_Var_Pop_Fields = {
  __typename?: 'test_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Test_Var_Samp_Fields = {
  __typename?: 'test_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Test_Variance_Fields = {
  __typename?: 'test_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  is_introduced: Scalars['Boolean'];
  is_verified: Scalars['Boolean'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  is_introduced?: InputMaybe<Boolean_Comparison_Exp>;
  is_verified?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  is_introduced?: InputMaybe<Scalars['Boolean']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  is_introduced?: InputMaybe<Order_By>;
  is_verified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsIntroduced = 'is_introduced',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  is_introduced?: InputMaybe<Scalars['Boolean']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  is_introduced?: InputMaybe<Scalars['Boolean']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsIntroduced = 'is_introduced',
  /** column name */
  IsVerified = 'is_verified',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Insert_Ip_And_HashMutationVariables = Exact<{
  ip_address: Scalars['String'];
  hash: Scalars['String'];
  email: Scalars['String'];
}>;


export type Insert_Ip_And_HashMutation = { __typename?: 'mutation_root', insert_images_one?: { __typename?: 'images', id: number } | null };

export type Insert_Form_ResponseMutationVariables = Exact<{
  nearest_location_hub: Scalars['String'];
  age_bracket: Scalars['String'];
  race: Scalars['String'];
  dominance_level: Scalars['String'];
  dominance_experience: Scalars['String'];
  domination_style: Scalars['String'];
  relationship_status: Scalars['String'];
  employment_status: Scalars['String'];
  fetishes: Scalars['String'];
  relationship_type_preference: Scalars['String'];
  control_level: Scalars['String'];
  relationship_location_preference: Scalars['String'];
  discretion_importance: Scalars['String'];
  financial_support: Scalars['String'];
  accomodation_type: Scalars['String'];
  training_access: Scalars['String'];
  email: Scalars['String'];
}>;


export type Insert_Form_ResponseMutation = { __typename?: 'mutation_root', insert_initial_answers_one?: { __typename?: 'initial_answers', id: number } | null };

export type Insert_Form_Response_TwoMutationVariables = Exact<{
  is_sexually_healthy: Scalars['String'];
  sexual_health_note: Scalars['String'];
  last_clinic_visit: Scalars['String'];
  clinic_visit_note: Scalars['String'];
  uploaded_file: Scalars['String'];
  extension: Scalars['String'];
  email: Scalars['String'];
}>;


export type Insert_Form_Response_TwoMutation = { __typename?: 'mutation_root', insert_second_responses_one?: { __typename?: 'second_responses', id: number } | null };

export type SendUserIpMutationVariables = Exact<{
  id: Scalars['Int'];
  ip: Scalars['String'];
}>;


export type SendUserIpMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: number } | null };

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserByEmailQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, name: string, is_introduced: boolean, is_verified: boolean }> };

export type GetUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, name: string, is_introduced: boolean, is_verified: boolean }> };


export const Insert_Ip_And_HashDocument = gql`
    mutation INSERT_IP_AND_HASH($ip_address: String!, $hash: String!, $email: String!) {
  insert_images_one(object: {ip: $ip_address, hash: $hash, email: $email}) {
    id
  }
}
    `;
export type Insert_Ip_And_HashMutationFn = Apollo.MutationFunction<Insert_Ip_And_HashMutation, Insert_Ip_And_HashMutationVariables>;

/**
 * __useInsert_Ip_And_HashMutation__
 *
 * To run a mutation, you first call `useInsert_Ip_And_HashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Ip_And_HashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIpAndHashMutation, { data, loading, error }] = useInsert_Ip_And_HashMutation({
 *   variables: {
 *      ip_address: // value for 'ip_address'
 *      hash: // value for 'hash'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsert_Ip_And_HashMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Ip_And_HashMutation, Insert_Ip_And_HashMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Ip_And_HashMutation, Insert_Ip_And_HashMutationVariables>(Insert_Ip_And_HashDocument, options);
      }
export type Insert_Ip_And_HashMutationHookResult = ReturnType<typeof useInsert_Ip_And_HashMutation>;
export type Insert_Ip_And_HashMutationResult = Apollo.MutationResult<Insert_Ip_And_HashMutation>;
export type Insert_Ip_And_HashMutationOptions = Apollo.BaseMutationOptions<Insert_Ip_And_HashMutation, Insert_Ip_And_HashMutationVariables>;
export const Insert_Form_ResponseDocument = gql`
    mutation INSERT_FORM_RESPONSE($nearest_location_hub: String!, $age_bracket: String!, $race: String!, $dominance_level: String!, $dominance_experience: String!, $domination_style: String!, $relationship_status: String!, $employment_status: String!, $fetishes: String!, $relationship_type_preference: String!, $control_level: String!, $relationship_location_preference: String!, $discretion_importance: String!, $financial_support: String!, $accomodation_type: String!, $training_access: String!, $email: String!) {
  insert_initial_answers_one(
    object: {nearest_location_hub: $nearest_location_hub, age_bracket: $age_bracket, race: $race, email: $email, dominance_level: $dominance_level, dominance_experience: $dominance_experience, domination_style: $domination_style, employment_status: $employment_status, relationship_status: $relationship_status, fetishes: $fetishes, relationship_type_preference: $relationship_type_preference, control_level: $control_level, relationship_location_preference: $relationship_location_preference, discretion_importance: $discretion_importance, financial_support: $financial_support, accomodation_type: $accomodation_type, training_access: $training_access}
  ) {
    id
  }
}
    `;
export type Insert_Form_ResponseMutationFn = Apollo.MutationFunction<Insert_Form_ResponseMutation, Insert_Form_ResponseMutationVariables>;

/**
 * __useInsert_Form_ResponseMutation__
 *
 * To run a mutation, you first call `useInsert_Form_ResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Form_ResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFormResponseMutation, { data, loading, error }] = useInsert_Form_ResponseMutation({
 *   variables: {
 *      nearest_location_hub: // value for 'nearest_location_hub'
 *      age_bracket: // value for 'age_bracket'
 *      race: // value for 'race'
 *      dominance_level: // value for 'dominance_level'
 *      dominance_experience: // value for 'dominance_experience'
 *      domination_style: // value for 'domination_style'
 *      relationship_status: // value for 'relationship_status'
 *      employment_status: // value for 'employment_status'
 *      fetishes: // value for 'fetishes'
 *      relationship_type_preference: // value for 'relationship_type_preference'
 *      control_level: // value for 'control_level'
 *      relationship_location_preference: // value for 'relationship_location_preference'
 *      discretion_importance: // value for 'discretion_importance'
 *      financial_support: // value for 'financial_support'
 *      accomodation_type: // value for 'accomodation_type'
 *      training_access: // value for 'training_access'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsert_Form_ResponseMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Form_ResponseMutation, Insert_Form_ResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Form_ResponseMutation, Insert_Form_ResponseMutationVariables>(Insert_Form_ResponseDocument, options);
      }
export type Insert_Form_ResponseMutationHookResult = ReturnType<typeof useInsert_Form_ResponseMutation>;
export type Insert_Form_ResponseMutationResult = Apollo.MutationResult<Insert_Form_ResponseMutation>;
export type Insert_Form_ResponseMutationOptions = Apollo.BaseMutationOptions<Insert_Form_ResponseMutation, Insert_Form_ResponseMutationVariables>;
export const Insert_Form_Response_TwoDocument = gql`
    mutation INSERT_FORM_RESPONSE_TWO($is_sexually_healthy: String!, $sexual_health_note: String!, $last_clinic_visit: String!, $clinic_visit_note: String!, $uploaded_file: String!, $extension: String!, $email: String!) {
  insert_second_responses_one(
    object: {email: $email, is_sexually_healthy: $is_sexually_healthy, sexual_health_note: $sexual_health_note, last_clinic_visit: $last_clinic_visit, clinic_visit_note: $clinic_visit_note, uploaded_file: $uploaded_file, extension: $extension}
  ) {
    id
  }
}
    `;
export type Insert_Form_Response_TwoMutationFn = Apollo.MutationFunction<Insert_Form_Response_TwoMutation, Insert_Form_Response_TwoMutationVariables>;

/**
 * __useInsert_Form_Response_TwoMutation__
 *
 * To run a mutation, you first call `useInsert_Form_Response_TwoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Form_Response_TwoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFormResponseTwoMutation, { data, loading, error }] = useInsert_Form_Response_TwoMutation({
 *   variables: {
 *      is_sexually_healthy: // value for 'is_sexually_healthy'
 *      sexual_health_note: // value for 'sexual_health_note'
 *      last_clinic_visit: // value for 'last_clinic_visit'
 *      clinic_visit_note: // value for 'clinic_visit_note'
 *      uploaded_file: // value for 'uploaded_file'
 *      extension: // value for 'extension'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsert_Form_Response_TwoMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Form_Response_TwoMutation, Insert_Form_Response_TwoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Form_Response_TwoMutation, Insert_Form_Response_TwoMutationVariables>(Insert_Form_Response_TwoDocument, options);
      }
export type Insert_Form_Response_TwoMutationHookResult = ReturnType<typeof useInsert_Form_Response_TwoMutation>;
export type Insert_Form_Response_TwoMutationResult = Apollo.MutationResult<Insert_Form_Response_TwoMutation>;
export type Insert_Form_Response_TwoMutationOptions = Apollo.BaseMutationOptions<Insert_Form_Response_TwoMutation, Insert_Form_Response_TwoMutationVariables>;
export const SendUserIpDocument = gql`
    mutation sendUserIP($id: Int!, $ip: String!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {ip: $ip}) {
    id
  }
}
    `;
export type SendUserIpMutationFn = Apollo.MutationFunction<SendUserIpMutation, SendUserIpMutationVariables>;

/**
 * __useSendUserIpMutation__
 *
 * To run a mutation, you first call `useSendUserIpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserIpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserIpMutation, { data, loading, error }] = useSendUserIpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ip: // value for 'ip'
 *   },
 * });
 */
export function useSendUserIpMutation(baseOptions?: Apollo.MutationHookOptions<SendUserIpMutation, SendUserIpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUserIpMutation, SendUserIpMutationVariables>(SendUserIpDocument, options);
      }
export type SendUserIpMutationHookResult = ReturnType<typeof useSendUserIpMutation>;
export type SendUserIpMutationResult = Apollo.MutationResult<SendUserIpMutation>;
export type SendUserIpMutationOptions = Apollo.BaseMutationOptions<SendUserIpMutation, SendUserIpMutationVariables>;
export const GetUserByEmailDocument = gql`
    query getUserByEmail($email: String!) {
  user(where: {email: {_eq: $email}}) {
    id
    name
    is_introduced
    is_verified
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserDocument = gql`
    query getUser($email: String!) {
  user(where: {email: {_eq: $email}}) {
    id
    name
    is_introduced
    is_verified
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;