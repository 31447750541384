import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "../validation";
import { SignInFormValues } from "../types";
import { fieldNames } from "../data";
import ErrorMessage from "../../../../shared/internals/components/errorMessage/ErrorMessage";
import Spinner from "../../../../shared/internals/components/spinner/Spinner";
import { logInWithEmailAndPassword } from "../../../../shared/infrastructure/firebase";
import Logo from "../../../../shared/internals/assets/logo.png";

export default function DuplicateSignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    resolver: yupResolver(signInValidationSchema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ email, password }: SignInFormValues) => {
    setLoading(true);
    await logInWithEmailAndPassword({ email, password });
    setLoading(false);
  };

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className=" h-fit">
        <div className="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true">
          <svg
            className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
            width={364}
            height={384}
            viewBox="0 0 364 384"
            fill="none"
          >
            <defs>
              <pattern
                id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} fill="currentColor" />
              </pattern>
            </defs>
            <rect width={364} height={384} fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
          </svg>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold  sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                      <span className="text-gray-900 md:block">Welcome to</span>{" "}
                      <span className="text-pink-400 md:block">Fetish Circle</span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      We are dedicated and committed to the advancement of{" "}
                      <span className="italic font-bold">Her</span>. This is a private,
                      referral-only platform. Access is strictly restricted to authorised
                      individuals.
                    </p>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div className="bg-white border-gray-100 border-2 sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                    <div className="px-4 py-8 sm:px-10">
                      <div>
                        <p className="text-sm font-bold text-gray-700">
                          Sign in with credentials you received via email
                        </p>
                        <p className="text-sm font-medium text-gray-400">
                          Check your junk folder, in case you can't find it
                        </p>
                      </div>

                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="px-2 bg-white text-gray-500"></span>
                        </div>
                      </div>

                      <div className="mt-6">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                          <div>
                            <label htmlFor={fieldNames.email} className="sr-only">
                              Email Address
                            </label>
                            <input
                              type="text"
                              placeholder="Email Address"
                              {...register("email")}
                              className="block w-full shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                          </div>

                          <div>
                            <label htmlFor={fieldNames.password} className="sr-only">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="Password"
                              {...register("password")}
                              className="block w-full shadow-sm focus:ring-pink-500 focus:border-pink-500 sm:text-sm border-gray-300 rounded-md"
                            />
                            {errors.password && (
                              <ErrorMessage>{errors.password.message}</ErrorMessage>
                            )}
                          </div>

                          <div>
                            <button
                              disabled={loading ? true : false}
                              type="submit"
                              className="w-full flex justify-center text-gray-100 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium  bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                            >
                              {loading ? "Loading" : "Sign In"}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
